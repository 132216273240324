import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Link, useSearchParams } from 'react-router-dom';
import { MdFastfood } from "react-icons/md";
import imgPizza from '../../img/Pizza2.png';

function Categoria({ categoria, itens, nomeEmpresaForUrl, idEmpresa, handleSelectCategoria, statusLoja }) {

  const [searchParams] = useSearchParams();

  // Obtém o external_id da URL atual, se existir
  const externalId = searchParams.get('external_id');

  const { ref, inView } = useInView({
    threshold: 1, // Ajuste este valor conforme necessário para melhorar a precisão
    rootMargin: "-140px 0px", // Adiciona uma margem extra para detecção
    onChange: (isVisible) => {
      //console.log(`Categoria ${categoria._id} visível: ${isVisible} ref: ${ref}`);
      if (isVisible) handleSelectCategoria(categoria._id);
    },
  });
  //console.log(inView)

  return (
    <div id={categoria._id} className="mapContainerCategorias">
      <div className="title-container-mobile">
        <h2>{categoria.title}</h2>
      </div>
      <div className="itens-mobile">
        {itens
          .filter((item) => item.category_item_id === categoria._id)
          .filter((item) => !item.out_salao)
          .map((item, index) => (
            <div key={item._id} className="itens-container-mobile">
              {/*<Link
                className="item-card"
                to={`/${nomeEmpresaForUrl}/${idEmpresa}/detalhes/${item._id}`}
                state={{ item, statusLoja }}
              >*/}
              <Link
                className="item-card"
                // Adiciona o external_id na URL se ele existir
                to={
                  externalId
                    ? `/${nomeEmpresaForUrl}/${idEmpresa}/detalhes-salao/${item._id}?external_id=${externalId}`
                    : `/${nomeEmpresaForUrl}/${idEmpresa}/detalhes-salao/${item._id}`
                }
                state={{ item, statusLoja }}
              >
                <div ref={index === 0 || index === 1 ? ref : null} className="text-info-mobile">
                  <h4>{item.title}</h4>
                  <p className="description-mobile">{item.description}</p>
                  <div className="price-mobile">
                    <p style={{ fontSize: 14 }}>R$ {item.price_salao?.toFixed(2).toString().replace('.', ',') || "0,00"}</p>
                  </div>
                </div>
                <div className="image-container-mobile">
                  <div className="picture-mobile">
                    {!item.image || item.image === "" ?
                        item?.type === "pizza" ?
                        <img className="h-56 w-full object-cover" width={90} height={90} src={imgPizza} alt="product" />
                        :
                        <div>
                          <MdFastfood style={{ fontSize: 90 }} />
                        </div>
                      :
                      <img width={90} height={90} loading="lazy" src={item.image} alt={item.title} />
                    }

                  </div>
                </div>
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Categoria;