import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import imgTeste from '../../img/imgTeste.png';
import imgPizza from '../../img/Pizza2.png';
import './styleMobile.css';
import ModalItemAdicionado from "../../components/ModalItemAdicionado";
import CryptoJS from 'crypto-js';
import { toast } from "react-toastify";
import ToastWithLink from './ToastWithLink';
import { getAdicionaisCardapio, getItemCardapio, getEmpresaWithObjId } from "../../services/api";
import { FaPlus, FaMinus, FaCheck } from 'react-icons/fa';
import { MdFastfood } from "react-icons/md";

const DetalheProduto = () => {
    const navigate = useNavigate();
    // Obtém o external_id da URL atual, se existir
    const [searchParams] = useSearchParams();
    const externalId = searchParams.get('external_id');
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const empresaEncrypted = sessionStorage.getItem('sessionCompany');
    //const empresa = empresaEncrypted ? JSON.parse(CryptoJS.AES.decrypt(empresaEncrypted, secretKey).toString(CryptoJS.enc.Utf8)) : null;
    const [empresa, setEmpresa] = useState(empresaEncrypted ? JSON.parse(CryptoJS.AES.decrypt(empresaEncrypted, secretKey).toString(CryptoJS.enc.Utf8)) : null);
    const location = useLocation();
    const [item, setItem] = useState(null);
    const [adicionais, setAdicionais] = useState([]);
    const { nomeEmpresaForUrl, idEmpresa, objIdProduto } = useParams();
    const [addedToCart, setAddedToCart] = useState(false);
    const [itensCarrinho, setItensCarrinho] = useState(() => {
        const itensFromStorage = Cookies.get('itensCarrinhoMobile');
        return itensFromStorage ? JSON.parse(itensFromStorage) : [];
    });
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [selectedItemNome, setSelectedItemNome] = useState(null);
    const [quantidades, setQuantidades] = useState({});
    const [animationTrigger, setAnimationTrigger] = useState({});
    const [valueDescricao, setValueDescricao] = useState('');
    const descriptionRef = useRef();
    //const statusLoja = location.state?.statusLoja;
    const [statusLoja, setStatusLoja] = useState(location.state?.statusLoja ? location.state.statusLoja : null);
    const [empresaObjId, setEmpresaObjId] = useState(null);

    useEffect(() => {
        const fetchItem = async () => {
            try {
                const response = await getItemCardapio(idEmpresa, objIdProduto);
                //console.log('response>', response);
                if (response.data.item) {
                    setItem(response.data.item);
                    //console.log("JSON.stringify(response.data.item):",JSON.stringify(response.data.item))
                    setEmpresaObjId(response.data.item.empresaObjId);
                }
            } catch (error) {
                console.error('Erro ao buscar item:', error);
            }
        };

        if (location.state?.item) {
            setItem(location.state?.item);
            //console.log("JSON.stringify(location.state?.item):",JSON.stringify(location.state?.item))
        } else {
            fetchItem();
        }
    }, []);

    const [openStatus, setOpenStatus] = useState("");
    const [horariosFuncionamento, setHorariosFuncionamento] = useState("");
    const [currentTime, setCurrentTime] = useState("");
    const [loadingData, setLoadingData] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        (async () => {

            try {

                if (empresaObjId) {
                    const empresaTemp = await getEmpresaWithObjId(empresaObjId);
                    if (empresaTemp) {
                        setEmpresa(empresaTemp.data.empresa);
                        // Converte o objeto empresa para uma string JSON e criptografa para salvamento no sessionStorage
                        const empresaString = JSON.stringify(empresaTemp.data.empresa);
                        const encryptedEmpresa = CryptoJS.AES.encrypt(empresaString, secretKey).toString();

                        if (empresaTemp.data.empresa.status_loja === "sempre") {
                            setStatusLoja("aberto");
                            setOpenStatus("Sempre aberto");
                        }
                        if (empresaTemp.data.empresa.status_loja === "especifico" && !empresaTemp.data.empresa.horario_funcionamento) {
                            setHorariosFuncionamento("Sem horário de funcionamento definido");
                            setCurrentTime("Sem horário de funcionamento definido");
                        }
                        if (empresaTemp.data.empresa.status_loja === "especifico" && empresaTemp.data.empresa.horario_funcionamento) {
                            setHorariosFuncionamento(empresaTemp.data.empresa.horario_funcionamento);
                        }
                        if (!empresaTemp.data.empresa.status_loja) {
                            if (openStatus === "" || openStatus === "Fechado") {
                                toast("O estabelecimento está fechado.", { autoClose: false, type: 'error', position: 'bottom-center' });
                                setOpenStatus("Fechado");
                            }
                        }
                        // Armazena a string JSON no sessionStorage
                        sessionStorage.setItem("sessionCompany", encryptedEmpresa);
                        //setLoadingData(false);  // Set loading to false after all states are updated
                    }
                    else {
                        //console.log("Nenhuma categoria encontrada!");
                        //setLoadingData(false);  // Set loading to false if no data is found
                    }
                }

            } catch (error) {
                if (error.response && error.response.status === 404) {
                    // Tratar o erro 404 Not Found
                    setErrorMessage("Erro 404: Página não encontrada");
                    setShowError(true);
                } else {
                    // Tratar outros erros
                    console.error("Unexpected error:", error);
                    setErrorMessage("Ocorreu um erro inesperado");
                    setShowError(true);
                }
                setLoadingData(false);  // Set loading to false on error
            }
        })();
    }, [empresaObjId]);

    useEffect(() => {
        const fetchAdicionais = async () => {
            try {
                const response = await getAdicionaisCardapio(idEmpresa, nomeEmpresaForUrl);
                const adicionaisFromDB = response.data.adicionais;
                console.log("item::", item)
                if (item?.adicionais && adicionaisFromDB) {
                    console.log("CHEGOU AQUI?")
                    const adicionaisEncontrados = adicionaisFromDB.filter(adicionalDB =>
                        item?.adicionais.some(adicionalItem => adicionalItem.value === adicionalDB._id)
                    );
                    setAdicionais(adicionaisEncontrados.map(adicional => ({
                        ...adicional,
                        quantidade: adicional.mandatory ? Math.max(1, adicional.min) : adicional.min || 0
                    })));
                }
            } catch (error) {
                console.error('Erro ao buscar adicionais:', error);
            }
        };

        if (item) {
            fetchAdicionais();
        }
    }, [idEmpresa, nomeEmpresaForUrl, item]);

    /*const handleAddToCart = () => {
        if (!statusLoja || statusLoja === 'fechado') {
            toast.error(<ToastWithLink nomeEmpresaForUrl={nomeEmpresaForUrl} idEmpresa={idEmpresa} empresa={empresa} navigate={navigate} />);
            return;
        }

        if (item) {
            const gruposSelecionados = adicionais.map(adicionalPai => ({
                grupoId: adicionalPai._id,
                grupoTitle: adicionalPai.title,
                calcular_maior_valor: adicionalPai.calcular_maior_valor,
                adicionais: adicionalPai.adicionais.map(adicional => ({
                    adicionalId: adicional._id,
                    title: adicional.title,
                    quantity: quantidades[adicional._id] || 0,
                    price: adicional.price
                })).filter(adicional => adicional.quantity > 0)
            })).filter(grupo => grupo.adicionais.length > 0);
    
            const newItem = {
                ...item,
                id: new Date().getTime(),
                quantity: 1,
                grupos_adicionais_selecionados: gruposSelecionados,
                observacoes_item: valueDescricao,
                price: item?.price || 0 //valorTotal().replace(',', '.')
            };
            const encryptedPrice = CryptoJS.AES.encrypt(newItem.price.toString(), secretKey).toString();
            const newItemWithoutImages = { ...newItem, price: encryptedPrice };
            delete newItemWithoutImages.image;
            setSelectedItemId(newItem.id);
            setSelectedItemNome(newItem.title);
            setItensCarrinho(prev => [...prev, newItemWithoutImages]);
            setAddedToCart(prevAddedToCart => !prevAddedToCart);
        }
    };*/
    const handleAddToCart = () => {
        if (!statusLoja || statusLoja === 'fechado') {
            toast.error(<ToastWithLink nomeEmpresaForUrl={nomeEmpresaForUrl} idEmpresa={idEmpresa} empresa={empresa} navigate={navigate} />);
            return;
        }

        if (item) {
            const valorBaseItem = item?.price_salao || 0; // Definindo o valor base do item
            const gruposSelecionados = adicionais.map(adicionalPai => ({
                grupoId: adicionalPai._id,
                grupoTitle: adicionalPai.title,
                calcular_maior_valor: adicionalPai.calcular_maior_valor,
                calcular_media: adicionalPai.calcular_media,
                adicionais: adicionalPai.adicionais.map(adicional => ({
                    adicionalId: adicional._id,
                    title: adicional.title,
                    quantity: quantidades[adicional._id] || 0,
                    price_salao: adicional.price_salao
                })).filter(adicional => adicional.quantity > 0)
            })).filter(grupo => grupo.adicionais.length > 0);

            // Usando o valor base do item e o valor total calculado.
            const newItem = {
                ...item,
                id: new Date().getTime(),
                quantity: 1,
                grupos_adicionais_selecionados: gruposSelecionados,
                observacoes_item: valueDescricao,
                price_salao: valorBaseItem, // Define o valor base do item para o carrinho.
                total: parseFloat(valorTotal().replace(',', '.')) // Define o valor total calculado para exibição.
            };


            /*const encryptedTotal = CryptoJS.AES.encrypt(newItem.total.toString(), secretKey).toString();
            const newItemWithoutImages = { ...newItem, total: encryptedTotal }; // Armazena apenas o valor total criptografado.
            delete newItemWithoutImages.image;*/
            // Criptografando o valor do price antes de salvar no localxStorage
            const encryptedPrice = CryptoJS.AES.encrypt(newItem.price_salao.toString(), secretKey).toString();
            const newItemWithEncryptedPrice = { ...newItem, price_salao: encryptedPrice };

            const encryptedTotal = CryptoJS.AES.encrypt(newItemWithEncryptedPrice.total.toString(), secretKey).toString();
            const newItemFinal = { ...newItemWithEncryptedPrice, total: encryptedTotal };

            delete newItemFinal.image; // Remover a imagem para economizar espaço, se necessário.

            setSelectedItemId(newItem.id);
            setSelectedItemNome(newItem.title);
            setItensCarrinho(prev => [...prev, newItemFinal]);
            setAddedToCart(prevAddedToCart => !prevAddedToCart);
        }
    };

    const handleButtonClick = () => {
        if (!isRequirementMet()) {
            toast("Por favor, selecione a quantidade mínima exigida nos adicionais obrigatórios antes de avançar.", { autoClose: 5000, type: 'error' });
        } else {
            handleAddToCart();
        }
    };

    /*const valorTotal = () => {
        let valorTotalItem = item?.price || 0;
        adicionais.forEach(adicionalPai => {
            //console.log("adicionalPai:",adicionalPai)
            adicionalPai.adicionais.forEach(adicional => {
                valorTotalItem += (quantidades[adicional._id] || 0) * adicional.price;
            });
        });
        return valorTotalItem.toFixed(2).toString().replace('.', ',');
    };*/
    /*const valorTotal = () => {
        let valorTotalItem = item?.price || 0;
    
        adicionais.forEach(adicionalPai => {
            if (adicionalPai.calcular_maior_valor) {
                // Se calcular_maior_valor for true, pegamos o maior valor entre os adicionais do grupo.
                const maxAdicionalValue = adicionalPai.adicionais.reduce((max, adicional) => {
                    const totalAdicional = (quantidades[adicional._id] || 0) * adicional.price;
                    return totalAdicional > max ? totalAdicional : max;
                }, 0);
    
                valorTotalItem += maxAdicionalValue;
            } else {
                // Caso contrário, somamos todos os valores dos adicionais multiplicados pela quantidade.
                adicionalPai.adicionais.forEach(adicional => {
                    valorTotalItem += (quantidades[adicional._id] || 0) * adicional.price;
                });
            }
        });
    
        return valorTotalItem.toFixed(2).toString().replace('.', ',');
    };*/
    const valorTotal = () => {
        // O valor base do item (o preço inicial sem considerar adicionais)
        let valorBaseItem = item?.price_salao || 0;

        // Considera a quantidade do item no cálculo do valor base
        let valorTotalItem = valorBaseItem * (item?.quantity || 1);

        adicionais.forEach(adicionalPai => {
            if (adicionalPai.calcular_maior_valor) {
                // Se calcular_maior_valor for true, pegamos apenas o maior valor entre os adicionais do grupo que possuem quantidade > 0.
                const maxAdicionalValue = adicionalPai.adicionais
                    .filter(adicional => (quantidades[adicional._id] || 0) > 0) // Considera apenas os adicionais selecionados (quantidade > 0)
                    .reduce((max, adicional) => {
                        const totalAdicional = adicional.price_salao; // Considera apenas o valor unitário do adicional
                        return totalAdicional > max ? totalAdicional : max;
                    }, 0);

                valorTotalItem += maxAdicionalValue;
            } else if (adicionalPai.calcular_media) {
                // Se calcular_media for true, calculamos a média dos valores dos adicionais selecionados.
                const adicionaisSelecionados = adicionalPai.adicionais
                    .filter(adicional => (quantidades[adicional._id] || 0) > 0); // Filtra apenas os adicionais com quantidade > 0

                if (adicionaisSelecionados.length > 0) {
                    // Calcula a média dos valores dos adicionais selecionados
                    const somaValores = adicionaisSelecionados.reduce((acc, adicional) => {
                        const quantidadeSelecionada = quantidades[adicional._id] || 0;
                        return acc + (adicional.price_salao * quantidadeSelecionada);
                    }, 0);
                    const mediaValor = somaValores / adicionaisSelecionados.length;
                    valorTotalItem += mediaValor;
                }

            } else {
                // Caso contrário, somamos todos os valores dos adicionais multiplicados pela quantidade.
                const totalAdicionais = adicionalPai.adicionais.reduce((acc, adicional) => {
                    const quantidadeSelecionada = quantidades[adicional._id] || 0;
                    return acc + (adicional.price_salao * quantidadeSelecionada);
                }, 0);

                valorTotalItem += totalAdicionais;
            }
        });

        // Retorna o valor total formatado para exibição
        return valorTotalItem.toFixed(2).toString().replace('.', ',');
    };

    const isRequirementMet = () => {
        return adicionais.every(adicionalPai => {
            if (!adicionalPai.mandatory) return true;

            const totalItems = adicionalPai.adicionais.reduce((acc, adicional) => {
                return acc + (quantidades[adicional._id] || 0);
            }, 0);

            return adicionalPai.min > 0 ? totalItems >= adicionalPai.min : true;
        });
    };

    /*useEffect(() => {
        const itensWithoutImages = itensCarrinho.map(item => {
            const { price, ...itemWithoutImages } = item;
            const decryptedPrice = CryptoJS.AES.decrypt(price, secretKey).toString(CryptoJS.enc.Utf8);
            //console.log("decryptedPrice:",decryptedPrice)
            const totalValue = item?.quantity * parseFloat(decryptedPrice);
            const encryptedTotalValue = CryptoJS.AES.encrypt(totalValue.toString(), secretKey).toString();
            return { ...itemWithoutImages, price, totalValue: encryptedTotalValue };
        });
        //console.log("itensWithoutImages:",itensWithoutImages)
        localxStorage.setItem('itensCarrinho', JSON.stringify(itensWithoutImages));
    }, [itensCarrinho]);*/
    useEffect(() => {
        const itensWithoutImages = itensCarrinho.map(item => {
            const { price_salao, ...itemWithoutImages } = item;
            const decryptedPrice = CryptoJS.AES.decrypt(price_salao, secretKey).toString(CryptoJS.enc.Utf8);
            const totalValue = item?.quantity * parseFloat(decryptedPrice);
            const encryptedTotalValue = CryptoJS.AES.encrypt(totalValue.toString(), secretKey).toString();
            return { ...itemWithoutImages, price_salao, totalValue: encryptedTotalValue };
        });
        // Salvar os itens no cookie com expiração de 1 hora, substituindo localxStorage
        Cookies.set('itensCarrinhoMobile', JSON.stringify(itensWithoutImages), { expires: 1 / 24 });  // 1/24 de um dia = 1 hora
    }, [itensCarrinho]);

    useEffect(() => {
        const itensFromStorage = Cookies.get('itensCarrinhoMobile');
        if (itensFromStorage) {
            setItensCarrinho(JSON.parse(itensFromStorage));
        }
    }, []);

    useEffect(() => {
        isRequirementMet();
    }, [quantidades]);

    const handleIncrement = (idPai, id) => {
        setQuantidades(prev => {
            const grupo = adicionais.find(adicional => adicional._id === idPai);
            const maxTotal = grupo.max;
            const calculaMediaOuMaiorValor = grupo.calcular_maior_valor || grupo.calcular_media;

            // Calcula o total de adicionais já selecionados no grupo (excluindo o atual)
            const totalAtual = grupo.adicionais.reduce((acc, adicional) => acc + (prev[adicional._id] || 0), 0);

            // Verifica se podemos adicionar mais adicionais sem ultrapassar o máximo
            if (totalAtual >= maxTotal) {
                return prev; // Não permite adicionar mais adicionais
            }

            // Verifica a quantidade do adicional específico que estamos tentando incrementar
            const totalAtualDoAdicional = prev[id] || 0;

            // Se o adicional tiver 'calcular_maior_valor' ou 'calcular_media', limita a 1
            if (calculaMediaOuMaiorValor && totalAtualDoAdicional >= 1) {
                return prev; // Se já houver 1, não permite adicionar mais
            }

            // Para adicionais normais, apenas limita o total no grupo
            if (!calculaMediaOuMaiorValor && totalAtual + 1 > maxTotal) {
                return prev; // Se o total no grupo exceder o maxTotal, não permite adicionar mais
            }

            // Se não houver violação de limites, incrementa o adicional
            setLastValues(last => ({ ...last, [id]: prev[id] || 0 }));
            setAnimationTrigger(trigger => ({ ...trigger, [id]: (trigger[id] || 0) + 1 }));
            return { ...prev, [id]: (prev[id] || 0) + 1 };
        });
    };

    const totalCurrent = (idPai) => {
        return adicionais.find(adicional => adicional._id === idPai).adicionais.reduce((acc, adicional) => {
            return acc + (quantidades[adicional._id] || 0);
        }, 0);
    };

    const isSelected = (adicionalPai) => {
        const totalItems = adicionalPai.adicionais.reduce((acc, adicional) => {
            return acc + (quantidades[adicional._id] || 0);
        }, 0);

        return totalItems >= (adicionalPai.min || 1);
    };

    const handleDecrement = (id) => {
        setQuantidades(prev => {
            if (prev[id] > 0) {
                setLastValues(last => ({ ...last, [id]: prev[id] }));
                setAnimationTrigger(trigger => ({ ...trigger, [id]: (trigger[id] || 0) + 1 }));
                return { ...prev, [id]: prev[id] - 1 };
            }
            return prev;
        });
    };

    const [lastValues, setLastValues] = useState({});

    const updateQuantity = (id, newQuantity) => {
        setQuantidades(prev => {
            const lastQuantity = prev[id] || 0;
            setLastValues(last => ({ ...last, [id]: lastQuantity }));
            return { ...prev, [id]: newQuantity };
        });
    };

    const getAnimationClass = (id) => {
        if (lastValues[id] === undefined) return '';
        return quantidades[id] > lastValues[id] ? 'slideDown' : 'slideUp';
    };

    /*const handleBack = () => {
        navigate('/cardapio/' + nomeEmpresaForUrl + '/' + idEmpresa);
    };*/
    const handleBack = () => {
        if (externalId) {
            // Se external_id existir, inclui ele na navegação
            navigate(`/cardapio-salao/${nomeEmpresaForUrl}/${idEmpresa}?external_id=${externalId}`);
        } else {
            // Caso contrário, navega sem external_id
            navigate(`/cardapio-salao/${nomeEmpresaForUrl}/${idEmpresa}`);
        }
    };

    const handleMaxCharDescricao = (e) => {
        const newValue = e.target.value;
        if (newValue.length <= 200) {
            setValueDescricao(newValue);
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    return (
        <div className="flex flex-col max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden md:max-w-2xl">
            <ModalItemAdicionado
                addedToCart={addedToCart}
                setAddedToCart={setAddedToCart}
                setItensCarrinho={setItensCarrinho}
                itensCarrinho={itensCarrinho}
                selectedItemId={selectedItemId}
                selectedItemNome={selectedItemNome}
            />
            <div className="backButtonDetalhe" onClick={handleBack}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={28}
                    height={28}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevron-left"
                >
                    <polyline points="15 18 9 12 15 6" />
                </svg>
            </div>
            <div className="compartilharButtonDetalhe">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.75"
                    height="22.75"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#3E3E3E"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-share-2"
                >
                    <circle cx={18} cy={5} r={3} />
                    <circle cx={6} cy={12} r={3} />
                    <circle cx={18} cy={19} r={3} />
                    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />
                    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
                </svg>
            </div>
            <div className="flex-shrink-0">
                {!item?.image || item?.image === "" ?
                    item?.type === "pizza" ?
                        <img className="h-56 w-full object-cover" width={390} height={370} src={imgPizza} alt="product" />
                        :
                        <div>
                            <MdFastfood style={{ fontSize: 390 }} />
                        </div>
                    :
                    <img className="h-56 w-full object-cover" width={390} height={370} src={item?.image} alt="product" />
                }

            </div>
            <div className="p-4">
                <h3 className="text-lg font-semibold">{item?.title}</h3>
                <p className="text-gray-700" style={{ color: "#1095F3", fontWeight: 600 }}>R$ {item?.price_salao?.toFixed(2).toString().replace('.', ',') || "0,00"}</p>
                <p className="mt-2 text-xs text-gray-500" dangerouslySetInnerHTML={{ __html: item?.description.replace(/\n/g, '<br/>') }}></p>
            </div>
            {console.log("ADICIONAIS:::", JSON.stringify(adicionais))}
            {adicionais && adicionais.length > 0 &&
                adicionais.sort((a, b) => {
                    // Ordena colocando os do tipo "pizza" no início
                    if (a.type === 'pizza' && b.type !== 'pizza') return -1;
                    if (a.type !== 'pizza' && b.type === 'pizza') return 1;
                    return 0; // Mantém a ordem original para os que têm o mesmo tipo
                }).map((adicionalPai, i) => (
                    <div key={i}>
                        {adicionalPai.adicionais && adicionalPai.adicionais.length > 0 && (
                            <div className="observacoesDetalhe">
                                <div className="obsTextBox" style={{ width: '100%' }}>
                                    <div className="adicionalTitleText">
                                        <div className="childrenDivTitleAdicionalCardapio">
                                            <span>{adicionalPai.title}</span>
                                            {/*adicionalPai.mandatory && (
                                                <>
                                                    <label className={isSelected(adicionalPai) ? "hidden" : "visible"}>Obrigatório</label>
                                                    <FaCheck className={isSelected(adicionalPai) ? "visible" : "hidden"} style={{ color: 'green', fontSize: '1.2em' }} />
                                                </>
                                            )*/}
                                        </div>
                                        {/*<p>
                                            Escolha {adicionalPai.mandatory && adicionalPai.min > 1 ? `no mínimo ${adicionalPai.min},` : null} até {adicionalPai.max === 1 ? adicionalPai.max + " opção." : adicionalPai.max + " opções."}
                                        </p>*/}
                                    </div>
                                </div>
                            </div>
                        )}
                        {adicionalPai.adicionais && adicionalPai.adicionais.length > 0 && adicionalPai.adicionais.map((adicional, index) => {
                            if (!adicional.out) {
                                return (
                                    <div key={index} className="containerPaiAdicionais">
                                        <div className={`divContendoAdicionais ${index === 0 ? 'first' : ''}`}>
                                            <div style={{ width: '70%', display: 'flex', flexDirection: 'row', gap: 7 }}>
                                                {
                                                    adicional.image === "" || !adicional.image ?
                                                        adicionalPai?.type === "pizza" ?
                                                            <img className="h-56 w-full object-cover" width={50} height={50} src={imgPizza} alt="product" />
                                                            :
                                                            <div>
                                                                <MdFastfood style={{ fontSize: 50 }} />
                                                            </div>
                                                        :
                                                        <img src={adicional.image} alt="img" className="imgAdicional" />
                                                }
                                                <div>
                                                    <label htmlFor={`adicional_${adicional._id}`} className="text-adicional-item">
                                                        {adicional.title}
                                                    </label>
                                                    <div style={{ color: "#1095F3", fontWeight: 500 }}>R$ {adicional.price_salao?.toFixed(2).replace('.', ',') || "0,00"}</div>
                                                </div>
                                            </div>
                                            {/*<div style={{ width: '25%' }} className="adicional-controls">
                                                <div onClick={() => handleDecrement(adicional._id)} style={{ visibility: quantidades[adicional._id] ? 'visible' : 'hidden' }}>
                                                    <FaMinus style={{ color: "orangered", fontSize: "16px", cursor: 'pointer' }} />
                                                </div>
                                                <div style={{ visibility: quantidades[adicional._id] ? 'visible' : 'hidden' }}>
                                                    <span className={`animated-number ${getAnimationClass(adicional._id)}`}
                                                        key={`${adicional._id}-${animationTrigger[adicional._id] || 0}`}>
                                                        {quantidades[adicional._id] || 0}
                                                    </span>
                                                </div>
                                                <div onClick={() => handleIncrement(adicionalPai._id, adicional._id)}
                                                    style={{
                                                        color: totalCurrent(adicionalPai._id) >= adicionalPai.max ? "#ccc" : "#1095F3",
                                                        fontSize: '20px',
                                                        marginBottom: 3,
                                                        cursor: totalCurrent(adicionalPai._id) >= adicionalPai.max ? 'not-allowed' : 'pointer'
                                                    }}
                                                    disabled={totalCurrent(adicionalPai._id) >= adicionalPai.max}>
                                                    <FaPlus />
                                                </div>
                                            </div>
                                            */}
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                ))}

            {/*<div className="observacoesDetalhe">
                <div className="obsTextBox">
                    <div className="obsText">
                        <span>Observações</span>
                    </div>
                </div>
            </div>
            <div className="obsTextBoxContainer">
                <div className="obsTextBoxData">
                    <textarea placeholder="Digite aqui a observação desejada"
                        value={valueDescricao} onChange={handleMaxCharDescricao}
                        maxLength="200" className="text-areaObs"
                        ref={descriptionRef}
                    />
                    <div className="divCounterChar">{valueDescricao.length}/200</div>
                </div>
            </div>
            <div className="avancarDetalhe">
                <button onClick={handleButtonClick}
                    className={`btnAvancarDetalhe ${!isRequirementMet() ? 'btnDisabledStyle' : ''}`}
                >
                    <p style={{ marginBottom: "0px" }}>Avançar</p>
                    <div className="btndata">R$ {valorTotal()}</div>
                </button>
            </div>
            */}
        </div>
    );
};

export default DetalheProduto;
