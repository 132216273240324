import React, { useContext, useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as HiIcons from "react-icons/hi";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import PermissionGate from "../../services/PermissionGate";
import "./style.css";
import userDiv from "../../img/userDiv2.png";
import defaultUserImg from "../../img/defaultUserImg.png";
import LogoP from "../../img/logoP.png";
import logoImg from "../../img/logoBlue.png";
import audioNotify from "../../assets/audio/soundNotify.mp3";
import { MdStorefront } from "react-icons/md";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaRobot } from "react-icons/fa";
import CryptoJS from "crypto-js";
import io from "socket.io-client";
import { ImPrinter } from "react-icons/im";
import ModalUserImg from "../ModalAddUserImg";
import ModalEditUser from "../../components/ModalEditUser";
import ModalLinkCardapio from "../ModalLinkCardapio";
import ModalLinkCardapioSalao from "../ModalLinkCardapioSalao";
import { RiMenuFoldFill } from "react-icons/ri";
import { RiMenuUnfoldFill } from "react-icons/ri";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdExitToApp } from "react-icons/md";
import roundTable from "../../img/round-table.png"
import entregadorIco from "../../img/entregador.png"

import { SidebarContext } from "../../AppRoutes";
import { AuthContext } from "../../contexts/auth";
import {
  getUser,
  getVinculoEmpresa,
  updateStatusBotEmpresa,
  changeStatusLoja,
  getDaysToExpireLicense,
} from "../../services/api";
import { Tooltip, Drawer } from "antd";

const Nav = styled.div`
  background: white;
  --background: linear-gradient(to left, #4281ff, #51d2ff);
  left: ${({ sidebar }) => (sidebar ? "250px" : "100px")};
  transition: 150ms;
  height: 80px;
  width: ${({ sidebar }) =>
    sidebar ? "calc(100% - 250px)" : "calc(100% - 100px)"};
  display: flex;
  --justify-content: flex-start;
  align-items: center;
  --border-bottom: 2px solid #0000001c;
  position: relative;
  z-index: 10;
  box-shadow: 1px 1px 6px rgb(180, 180, 180);

  @media (max-width: 880px){
    left: 0;
    width: 100%;
  }
`;

//const NavIcon = styled(Link)`
const NavIcon = styled.div`
  justify-content: center;
  font-size: 1rem;
  height: 20px;
  display: flex;
  align-items: center;
  color: white;
  text-decoration: unset;
  transition: font-size 150ms;

`;

const SidebarNav = styled.nav`
  background: white;
  width: ${({ sidebar }) => (sidebar ? "250px" : "100px")};
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: width 150ms cubic-bezier(0.4, 0, 0.2, 1); // Ajustado para uma curva bezier comum para movimento
  z-index: 10;
  border-right: solid 1px rgb(240, 240, 240);

  span {
    transition-delay: 200ms;
    transition-property: font-size, visibility, opacity; // Adicionando propriedades específicas
    visibility: ${({ sidebar }) => (sidebar ? "visible" : "hidden")};
    opacity: ${({ sidebar }) => (sidebar ? "1" : "0")};
    font-size: ${({ sidebar }) => (sidebar ? "14px" : "0px")};
    transition: visibility 0s, opacity 0.5s,
      ${({ sidebar }) =>
    sidebar ? "font-size 250ms linear" : "font-size 100ms linear"};
  }

  @media (max-width: 880px) {
    display: none;
  }
`;

const ModalUserOptions = styled.div`
  font-size: 14px;
  position: absolute;
  top: 82px;
  left: 150px;
  display: ${({ showOptions }) => (showOptions ? "none" : "")};
  float: left;
  min-width: 160px;
  margin: 2px 0 0;
  padding: 5px 0;
  list-style: none;
  text-align: left;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: #fff;
  --background-color: rgb(247, 247, 247) !important;
  background-clip: padding-box;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  z-index: 25;

  li {
    font-weight: 400;
    line-height: 1.42857143;
    display: block;
    clear: both;
    padding: 3px 20px;
    white-space: nowrap;
    color: #58595b;
  }
  li:hover {
    background: #f5f5f5;
  }
`;

/*
const OrdercolumnPrint = styled.div`
    min-width: 600px;
    max-width: 600px; 
    min-height: 1300px;
    max-height: 1300px;       
    position: absolute;
    top: 10%;
    left: 50%;
    font-size:26px;
    z-index:500
`;*/

const SidebarWrap = styled.div`
  width: 100%;
`;

const LeftMenu = (/*{setSidebar , sidebar}*/) => {
  const { sidebar, setSidebar } = useContext(SidebarContext);

  const [showEditUser, setEditUser] = useState(true);
  const [_idUserEdit, set_idUserEdit] = useState("");
  const [usernameEdit, setUsernameEdit] = useState("");
  const [emailEdit, setEmailEdit] = useState("");
  const [roleEdit, setRoleEdit] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const INITIAL_DATA = {
    value: "",
    label: "Selecione uma empresa",
  };
  const [selectData, setselectData] = useState(INITIAL_DATA);
  const navigate = useNavigate();

  const [dropstatusrobo, setDropStatusRobo] = useState(false);
  const [statusLojaTemporario, setStatusLojaTemporario] = useState(false);

  const { logout } = useContext(AuthContext);
  const secretKey =
    "my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be";

  const userEncrypted = localStorage.getItem("user");
  const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(
    CryptoJS.enc.Utf8
  );
  const userParse = JSON.parse(user);
  //console.log(userParse)
  //const userParse = user;
  const userID = userParse._id;
  const userName = userParse.name;
  const userEmail = userParse.email;
  var userImg = null;
  if (userParse.user_img?.length > 0) {
    userImg = userParse.user_img[0];
  }

  const empresa = localStorage.getItem("empresa");
  const empresaParse = JSON.parse(empresa);
  const idEmpresa = empresaParse.id_empresa;
  const objIdEmpresa = empresaParse._id;
  const cnpj = empresaParse.cnpj;
  const razao = empresaParse.razao;
  const nomeEmpresa = empresaParse.name;
  const nomeEmpresaForUrl = nomeEmpresa
    .replace(/\s+/g, "-")
    .replace(/[A-Z]/g, (c) => c.toLowerCase());

  const [showLinkCardapio, setShowLinkCardapio] = useState(false);
  const [showLinkCardapioSalao, setShowLinkCardapioSalao] = useState(false);
  const [showOptions, setUserOptions] = useState(true);
  const [showModalImg, setModalImg] = useState(true);
  const userOptionsRef = useRef();
  const leftMenuRef = useRef();
  const menuStatusRoboRef = useRef();
  const menuStatusRoboRef_ = useRef();
  const [statusBot, setStatusBot] = useState(false);
  var imageDataURL = null;

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  const handleLogout = () => {
    logout();
  };

  const handleSwitchChange = async () => {
    const newStatus = !statusBot;
    setStatusBot(newStatus);
    console.log("Status atualizado:", newStatus);
    try {
      const response = await updateStatusBotEmpresa(objIdEmpresa, newStatus);
      console.log("Resposta do servidor:", response);
    } catch (error) {
      console.error("Erro ao atualizar status_bot:", error);
    }
  };

  // Verifique se o navegador suporta notificações
  if (!("Notification" in window)) {
    alert("Este navegador não suporta notificações de sistema.");
  } else if (Notification.permission !== "denied") {
    // Pede permissão ao usuário
    Notification.requestPermission();
  }

  const handleEdit = async (idToEdit) => {
    setEditUser(!showEditUser);
    setUserOptions(!showOptions);
    const response = await getUser(idToEdit);
    //console.log("Infos do Edit:",response.data.user)
    //console.log(idToEdit,"----");
    if (showEditUser) {
      set_idUserEdit(idToEdit);
      setUsernameEdit(response.data.user.name);
      setEmailEdit(response.data.user.email);
      setRoleEdit(response.data.user.role);

      if (response.data.user.vinculo_empresa) {
        //console.log("TEM EMPRESA VINCULADA!")
        const responseVinculo = await getVinculoEmpresa(idToEdit);
        if (responseVinculo.data.vinculo) {
          setselectData({
            value: responseVinculo.data.vinculo.id_empresa,
            label:
              responseVinculo.data.vinculo.id_empresa +
              " - " +
              responseVinculo.data.vinculo.cnpj +
              " - " +
              responseVinculo.data.vinculo.name,
          });
        }
        if (!responseVinculo.data.vinculo) {
          //console.log("MSG:", responseVinculo.data.msg)
        }
      } else {
        //console.log("NÃO TEM EMPRESA VINCULADA!")
        setselectData({
          value: "",
          label: "",
        });
      }
    }
  };

  const showUserOptions = () => {
    setUserOptions(!showOptions);
  };

  const showModalAddUserImg = () => {
    setUserOptions(!showOptions);
    setModalImg(!showModalImg);
  };

  //const [pedidos, setPedidos] = useState([])
  const [statusPrinter, setStatusPrinter] = useState("");
  const [daysToExpire, setDaysToExpire] = useState("");
  const [planType, setPlanType] = useState("");
  useEffect(() => {
    (async () => {
      try {
        // Obtendo o vínculo da empresa
        const response = await getVinculoEmpresa(userID);
        //console.log("TESTE:", response.data);
        setStatusLojaTemporario(response.data.vinculo.fechamento_temporario);
        setStatusBot(response.data.vinculo.status_bot);
        setStatusPrinter(response.data.vinculo.status_printer);

        //const lastCheck = localStorage.getItem('ldc'); // Nome genérico para o timestamp
        const lastCheckEncrypted = localStorage.getItem("ldc");
        const lastCheck = lastCheckEncrypted
          ? CryptoJS.AES.decrypt(lastCheckEncrypted, secretKey).toString(
            CryptoJS.enc.Utf8
          )
          : null;
        const now = Date.now();
        const threeHoursInMs = 10 * 60 * 1000; // 10 minutos em milissegundos

        //if (!lastCheck || now - lastCheck > threeHoursInMs) {
        // Obtendo os dias restantes para expiração da licença
        const responseCheckLicense = await getDaysToExpireLicense(
          response.data.vinculo._id
        );
        //console.log("responseCheckLicense:", responseCheckLicense);
        setDaysToExpire(responseCheckLicense.data.daysRemaining || ""); // Definir valor padrão vazio
        setPlanType(responseCheckLicense.data.plan_type || ""); // Definir valor padrão vazio

        // Atualiza o timestamp da última verificação no localStorage
        const nowEncrypted = CryptoJS.AES.encrypt(
          JSON.stringify(now),
          secretKey
        ).toString();
        //localStorage.setItem('user', loggedUserEncrypted);
        localStorage.setItem("ldc", nowEncrypted);
        //}
      } catch (error) {
        console.error("Erro ao obter dados:", error);
      }
    })();
    // eslint-disable-next-line
  }, []);
  /*useEffect(() => {
    ////const intervalId = setInterval(() => {
    ////  fetchData().then(newPedidos => setPedidos(newPedidos));
    ////}, 5 * 1000); // Atualizar a cada 5 segundo

    var i;
    if(pedidos){
      for(i=0; i<pedidos.length; i++){
          //console.log("tipoImpressao>",tipoImpressao);
          if(pedidos[i].status_pedido=='2' && tipoImpressao == 'automatico'){
              //console.log(pedidos[i])
              //console.log("CHGEOU AUQI?");
              const orderElement = document.getElementById(`${pedidos[i].id_pedido}`);

              //console.log(orderElement);
              if (orderElement && orderElement.getAttribute('data-status') == "true") {
                updateStatusPrint(userID, pedidos[i]._id, pedidos[i].id_pedido).then(printPdf(pedidos[i].id_pedido));
              }
          }
      }
    }
    ////return () => clearInterval(intervalId);
  }, [pedidos]); // Sem dependências, então o efeito será executado apenas uma vez*/

  //const [statusImpressora, setStatusImpressora] = useState('');
  const isDevelopment = window.location.hostname === "localhost";
  const apiUrl = isDevelopment
    ? process.env.REACT_APP_SERVER_URL_DEV
    : process.env.REACT_APP_SERVER_URL_PROD;
  useEffect(() => {
    const wsUrl = apiUrl; // Alterar a URL se o servidor estiver em um endereço diferente
    const socket = io(wsUrl, {
      withCredentials: true,
      transports: ["websocket"],
      auth: { token: localStorage.getItem("token") },
    });
    //console.log(idEmpresa)
    socket.emit("joinCompanyRoom", {
      companyId: idEmpresa.toString(),
      clientId: "reactClient",
    });

    socket.on("novoPedido", (data) => {
      //console.log(data.msg, data.pedido);
      console.log("NOVO PEDIDO RECEBIDO!");
      handleNotify();
    });

    socket.on("statusUpdate", ({ companyId: updatedCompanyId, status }) => {
      if (idEmpresa.toString() === updatedCompanyId) {
        console.log(`Status atualizado da impressora: ${status}`);
        setStatusPrinter(status === "Online" ? "Online" : "Offline");
      }
    });

    return () => {
      socket.off("novoPedido");
      socket.off("statusUpdate");
      socket.disconnect();
    };
  }, []);

  const sendNotification = (title, options) => {
    // Verifica se o usuário aceitou receber notificações
    if (Notification.permission === "granted") {
      const notification = new Notification(title, {
        ...options,
        icon: LogoP, // Certifique-se de que o caminho para o ícone está correto
      });

      // Toca um som quando a notificação é exibida
      notification.onshow = () => {
        const audio = new Audio(audioNotify);
        audio
          .play()
          .catch((error) =>
            console.log("Erro ao reproduzir o som da notificação:", error)
          );
      };
    }
  };

  const handleNotify = () => {
    const title = "Pede Já - Novo Pedido";
    const options = {
      body: "Você recebeu um novo pedido.",
      // O campo 'sound' ainda não é amplamente suportado
      sound: audioNotify, // caminho para o arquivo de áudio
    };
    sendNotification(title, options);
  };

  const handleOpenCloseLoja = async () => {
    console.log("Fechar Loja");
    const newStatus = !statusLojaTemporario;
    try {
      const response = await changeStatusLoja(objIdEmpresa, newStatus);
      if (response.status === 200) {
        setStatusLojaTemporario(newStatus);
      } else {
        console.error("Falha ao atualizar o status da loja:", response);
      }
    } catch (error) {
      console.error("Erro ao chamar a API:", error);
    }
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClickOutsideOptions);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideOptions);
    };
  }, []);

  const handleClickOutsideOptions = (y) => {
    if (userOptionsRef.current.contains(y.target)) {
      return;
    } else {
      setUserOptions(true);
    }
  };

  useEffect(() => {
    // add when mounted
    //document.addEventListener("mouseover", handleMouseOverLeftMenu);
    //document.addEventListener("mouseout", handleMouseOutLeftMenu);

    window.addEventListener("resize", handleResize);
    // return function to be called when unmounted
    return () => {
      //document.removeEventListener("mouseover", handleMouseOverLeftMenu);
      //document.removeEventListener("mouseout", handleMouseOutLeftMenu);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    const currentWidth = window.innerWidth;
    if (currentWidth < 1300) {
      setSidebar(false);
    }
  }

  const handleMouseOverStatusRobo = (y) => {
    if (
      (menuStatusRoboRef.current &&
        menuStatusRoboRef.current.contains(y.target)) ||
      (menuStatusRoboRef_.current &&
        menuStatusRoboRef_.current.contains(y.target))
    ) {
      setDropStatusRobo(true);
      return;
    }
  };

  const handleMouseOutStatusRobo = (y) => {
    if (
      (menuStatusRoboRef.current &&
        !menuStatusRoboRef.current.contains(y.target)) ||
      (menuStatusRoboRef_.current &&
        !menuStatusRoboRef_.current.contains(y.target))
    ) {
      setDropStatusRobo(false);
      return;
    }
  };

  const handleToggleStatusRobo = () => {
    setDropStatusRobo((previous) => !previous);
  }

  return (
    <>
      <ModalUserImg
        setModalImg={setModalImg}
        showModalImg={showModalImg}
        userID={userID}
      />

      <ModalEditUser
        setEditUser={setEditUser}
        showEditUser={showEditUser}
        setRefresh={setRefresh}
        selectData={selectData}
        setselectData={setselectData}
        _idUserEdit={_idUserEdit}
        usernameEdit={usernameEdit}
        emailEdit={emailEdit}
        roleEdit={roleEdit}
        editPerfil={true}
      />

      <ModalLinkCardapio
        showLinkCardapio={showLinkCardapio}
        setShowLinkCardapio={setShowLinkCardapio}
        nomeEmpresaForUrl={nomeEmpresaForUrl}
      />

      <ModalLinkCardapioSalao
        showLinkCardapioSalao={showLinkCardapioSalao}
        setShowLinkCardapioSalao={setShowLinkCardapioSalao}
        nomeEmpresaForUrl={nomeEmpresaForUrl}
      />

      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav sidebar={sidebar} style={{ justifyContent: "space-between" }}>
          <div className="d-flex ms-3 align-items-center">
            <div onClick={showDrawer} className="desktop-hidden me-4" style={{ cursor: "pointer" }}>
              <GiHamburgerMenu color="black" />
            </div>
            <div
              className="userCircleImg"
              style={{
                paddingTop: "4px",
                paddingBottom: "2px",
              }}
            >
              <img src={logoImg} alt="pede-ja logo" width={120} height={40} className="logoImg" />
            </div>
          </div>
          <div className="d-flex">
            {/* Dropdown Menu Status Robo */}
            <div
              ref={menuStatusRoboRef_}
              className={
                dropstatusrobo ? "menu-dropdownShow" : "menu-dropdownClosed"
              }
            >
              <div className="menu-options">
                <div className="option bottom">
                  <div className="info-option">
                    <div className="status-option online" />
                    <div className="label-option">
                      <pedeja-icon
                        iconname="whatsapp"
                        iconstroke={2}
                        iconcolor="#797878"
                        iconsize={18}
                        _nghost-ng-c3181319476=""
                        style={{ height: 18 }}
                      >
                        <svg
                          width={18}
                          height={18}
                          fill="#797878"
                          viewBox="0 0 13 13"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.477 7.79066C9.31409 7.70819 8.52069 7.32103 8.37241 7.26578C8.22412 7.21297 8.11647 7.18534 8.00841 7.34825C7.90278 7.50709 7.59159 7.87069 7.49653 7.97672C7.40147 8.08275 7.30803 8.09088 7.14756 8.01897C6.98466 7.9365 6.46425 7.76709 5.84634 7.21297C5.36372 6.78356 5.04237 6.25462 4.94691 6.09172C4.85184 5.93084 4.93634 5.83984 5.01678 5.75941C5.09072 5.68547 5.17969 5.57334 5.26216 5.47584C5.34056 5.37834 5.36575 5.31294 5.42303 5.20731C5.47584 5.09316 5.44862 5.00419 5.40841 4.92375C5.36819 4.84331 5.04441 4.04584 4.90912 3.72816C4.77994 3.41291 4.64466 3.45312 4.54512 3.45312C4.45209 3.44459 4.34403 3.44459 4.23638 3.44459C4.12872 3.44459 3.95281 3.48481 3.80453 3.63919C3.65625 3.80209 3.23741 4.19128 3.23741 4.97859C3.23741 5.76794 3.81712 6.53169 3.89756 6.64584C3.98003 6.75147 5.03791 8.37647 6.66087 9.07481C7.04803 9.23772 7.34866 9.33522 7.58347 9.41566C7.97062 9.53834 8.32406 9.52128 8.60316 9.48106C8.91191 9.43028 9.55947 9.08944 9.69516 8.70878C9.83288 8.32569 9.83288 8.00841 9.79266 7.9365C9.75244 7.86256 9.64681 7.82234 9.48391 7.75044L9.477 7.79066ZM6.53372 11.7812H6.52519C5.56441 11.7812 4.61459 11.5208 3.78503 11.0342L3.59044 10.918L1.55919 11.4469L2.10519 9.4705L1.97397 9.26738C1.4375 8.41439 1.1529 7.42722 1.15294 6.41956C1.15294 3.47019 3.56728 1.06437 6.53778 1.06437C7.97672 1.06437 9.32669 1.625 10.3423 2.64062C10.8433 3.13568 11.2407 3.72555 11.5114 4.37582C11.782 5.0261 11.9204 5.72376 11.9186 6.42809C11.9145 9.37544 9.50219 11.7812 6.53575 11.7812H6.53372ZM11.1146 1.86834C9.87878 0.674781 8.25378 0 6.52519 0C2.95994 0 0.056875 2.89047 0.0548438 6.44272C0.0548438 7.57697 0.351 8.68359 0.918125 9.66306L0 13L3.432 12.105C4.38275 12.6176 5.44547 12.8872 6.52559 12.8899H6.52763C10.0949 12.8899 12.998 9.99944 13 6.44475C13 4.72469 12.3293 3.10578 11.1065 1.88906L11.1146 1.86834Z"
                            fill="#797878"
                          />
                        </svg>
                      </pedeja-icon>
                      <span className="description">Whatsapp</span>
                    </div>
                  </div>

                  {/* Switch Personalized */}
                  {/*
                    <div className="wrapper">
                        <div className="switch_box box_1">
                          <input type="checkbox" className="switch_1"/>
                        </div>
                    </div>
                    */}
                  <div className="wrapper">
                    <div className="switch_box box_1">
                      <input
                        type="checkbox"
                        className="switch_1"
                        checked={statusBot}
                        onChange={handleSwitchChange}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="option bottom-last"
                  style={{ marginBottom: "5px" }}
                >
                  <div className="info-option">
                    <div className="status-option online" />
                    <div
                      className="label-option"
                      style={{ display: "inline-flex", alignItems: "center" }}
                    >
                      <pedeja-icon
                        iconname="users"
                        iconstroke={2}
                        iconcolor="#797878"
                        iconsize={18}
                        _nghost-ng-c3181319476=""
                        style={{ height: 18 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#797878"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-users"
                        >
                          <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                          <circle cx={9} cy={7} r={4} />
                          <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                        </svg>
                      </pedeja-icon>
                      <div style={{ display: "inline-grid", lineHeight: "17px" }}>
                        <span className="description">Chamar</span>
                        <span className="description">Atendente</span>
                      </div>
                    </div>
                  </div>
                  {/* Switch Personalized */}
                  <div className="wrapper">
                    <div className="switch_box box_1">
                      <input type="checkbox" className="switch_1" />
                    </div>
                  </div>
                </div>
              </div>
              {/**/}
            </div>
            {/* Fim Dropdown Menu Status Robo */}
            <div
              style={{
                padding: "5px 24px 5px 24px",
                borderLeft: "1px solid rgb(208, 209, 209)",
                height: 80,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "2px",
                display: "inline-flex",
              }}
              ref={menuStatusRoboRef}
              onClick={handleToggleStatusRobo}
            //dropstatusrobo={dropstatusrobo}
            //className={dropstatusrobo ? 'open' : 'closed'}
            >
              <div className="hidden-mobile">
                <span style={{ fontSize: 15, fontWeight: 500, marginLeft: 5 }}>
                  Status
                </span>
              </div>
              <FaRobot style={{ color: "black", fontSize: 24 }} />
              <div
                className="hidden-mobile"
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 15,
                  position: "relative"
                }}
              >
                <span>Robo Pede Já</span>
                <RiArrowDropDownLine style={{ color: "black", fontSize: 24 }} />
              </div>
            </div>


            <Tooltip
              placement="bottom"
              title={`Impressora: ${statusPrinter === "Offline" ? 'Offline' : 'Conectada'}`}
            >
              <div style={{
                padding: "5px 24px 5px 24px",
                borderLeft: "1px solid rgb(208, 209, 209)",
                height: 80,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                display: "inline-flex",
              }}>
                <ImPrinter color={statusPrinter === "Offline" ? 'red' : '#07c670'} fontSize={30} />
                <div className="hidden-mobile">
                  <div>
                    <span style={{ fontSize: 15, fontWeight: 500, marginLeft: 5 }}>
                      Impressora
                    </span>
                  </div>
                  {statusPrinter && statusPrinter === "Offline" ? (
                    <div className="divStatusPrintNavBarOffline">
                      <span>Offline</span>
                    </div>
                  ) : (
                    <div className="divStatusPrintNavBar">
                      <span>Conectada</span>
                    </div>
                  )}
                </div>
              </div>
            </Tooltip>
            <Tooltip
              placement="bottom"
              title={`Cardápio Delivery`}
            >
              <div
                style={{
                  padding: "5px 24px",
                  borderLeft: "1px solid rgb(208, 209, 209)",
                  height: 80,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2px",
                  display: "inline-flex",
                }}
              >
                <div
                  onClick={() => setShowLinkCardapio(true)}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src={entregadorIco} height={30} width={30} />
                  <div className="d-flex flex-column hidden-mobile">
                    <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 5 }}>
                      Link
                    </span>
                    <span style={{ fontSize: 14, fontWeight: 700, marginLeft: 5 }}>
                      Cardápio Delivery
                    </span>
                  </div>
                </div>
              </div>
            </Tooltip>

            <Tooltip
              placement="bottom"
              title={`Cardápio Salão`}
            >
              <div
                style={{
                  padding: "5px 24px",
                  borderLeft: "1px solid rgb(208, 209, 209)",
                  height: 80,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2px",
                  display: "inline-flex",
                }}
              >
                <div
                  onClick={() => setShowLinkCardapioSalao(true)}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src={roundTable} height={30} width={30} />
                  <div className="d-flex flex-column hidden-mobile">
                    <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 5 }}>
                      Link
                    </span>
                    <span style={{ fontSize: 14, fontWeight: 700, marginLeft: 5 }}>
                      Cardápio Salão
                    </span>
                  </div>
                </div>
              </div>
            </Tooltip>

            <div
              style={{
                padding: "5px 24px 5px 24px",
                height: 80,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "2px",
                display: "inline-flex",
                borderLeft: "1px solid rgb(208, 209, 209)"
              }}
              className="hidden-sm-mobile"
            >
              <div>
                <MdStorefront style={{ color: "black", fontSize: 20 }} />
                <span style={{ fontSize: 15, fontWeight: 500, marginLeft: 5 }}>
                  Loja
                  {!statusLojaTemporario ? (
                    <div
                      style={{
                        width: 70,
                        height: 18,
                        borderRadius: 10,
                        marginLeft: 10,
                        background: "#9CE8C6",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 8,
                        display: "inline-flex",
                      }}
                    >
                      <span>Aberta</span>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 80,
                        height: 18,
                        borderRadius: 10,
                        marginLeft: 10,
                        background: "#ff0000b5",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 8,
                        color: "white",
                        fontSize: 13,
                        display: "inline-flex",
                      }}
                    >
                      <span>Fechada</span>
                    </div>
                  )}
                </span>
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 15,
                }}
              >
                <span>{nomeEmpresa}</span>
              </div>
              {statusLojaTemporario ? (
                <div
                  type="button"
                  onClick={() => handleOpenCloseLoja()}
                  style={{
                    width: 150,
                    height: 20,
                    borderRadius: 5,
                    background: "#318CD5",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 8,
                    display: "inline-flex",
                  }}
                >
                  <a style={{ color: "white", fontSize: 10 }}>Abrir Loja</a>
                </div>
              ) : (
                <div
                  type="button"
                  onClick={() => handleOpenCloseLoja()}
                  style={{
                    width: 150,
                    height: 20,
                    borderRadius: 5,
                    background: "#318CD5",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 8,
                    display: "inline-flex",
                  }}
                >
                  <a style={{ color: "white", fontSize: 10 }}>Fechar Loja</a>
                </div>
              )}
            </div>
            <div className="hidden-mobile" style={{ width: 128, height: 80, paddingLeft: 24, paddingRight: 24, borderLeft: '1px #D0D1D1 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'inline-flex' }}>
              <div onClick={handleLogout} style={{ textAlign: 'center', cursor: "pointer", color: '#001B30', fontSize: 18, fontWeight: '700', letterSpacing: 0.48, wordWrap: 'break-word' }}>
                <MdExitToApp style={{ color: 'black', fontSize: 25 }} />Sair
              </div>
            </div>
          </div>
        </Nav>
        <Drawer title="Navegação" onClose={onClose} open={open} key="left" placement="left" bodyStyle={{ padding: 0 }}>
          <div className="d-flex flex-column justify-content-between h-100">
            <div
              className="boxLeftMenuScroll"
              style={{ overflowY: "scroll", height: "calc(100vh - 120px)" }}
            >
              {SidebarData.map((item, index) => {
                return (
                  <PermissionGate
                    key={index}
                    permissions={[SidebarData[index].permission]}
                  >
                    <SubMenu
                      item={item}
                      key={index}
                      style={{ background: "black" }}
                    />
                  </PermissionGate>
                );
              })}
            </div>
            <div className="d-flex flex-column">
              <div
                style={{
                  padding: "5px 24px 5px 24px",
                  borderLeft: "1px solid rgb(208, 209, 209)",
                  height: 80,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2px",
                  display: "inline-flex",
                }}
              >
                <div>
                  <MdStorefront style={{ color: "black", fontSize: 20 }} />
                  <span style={{ fontSize: 15, fontWeight: 500, marginLeft: 5 }}>
                    Loja
                    {!statusLojaTemporario ? (
                      <div
                        style={{
                          width: 70,
                          height: 18,
                          borderRadius: 10,
                          marginLeft: 10,
                          background: "#9CE8C6",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 8,
                          display: "inline-flex",
                        }}
                      >
                        <span>Aberta</span>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: 80,
                          height: 18,
                          borderRadius: 10,
                          marginLeft: 10,
                          background: "#ff0000b5",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 8,
                          color: "white",
                          fontSize: 13,
                          display: "inline-flex",
                        }}
                      >
                        <span>Fechada</span>
                      </div>
                    )}
                  </span>
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: 15,
                  }}
                >
                  <span>{nomeEmpresa}</span>
                </div>
                {statusLojaTemporario ? (
                  <div
                    type="button"
                    onClick={() => handleOpenCloseLoja()}
                    style={{
                      width: 150,
                      height: 20,
                      borderRadius: 5,
                      background: "#318CD5",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 8,
                      display: "inline-flex",
                    }}
                  >
                    <a style={{ color: "white", fontSize: 10 }}>Abrir Loja</a>
                  </div>
                ) : (
                  <div
                    type="button"
                    onClick={() => handleOpenCloseLoja()}
                    style={{
                      width: 150,
                      height: 20,
                      borderRadius: 5,
                      background: "#318CD5",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 8,
                      display: "inline-flex",
                    }}
                  >
                    <a style={{ color: "white", fontSize: 10 }}>Fechar Loja</a>
                  </div>
                )}
              </div>
              <div className="hidden-mobile" style={{ width: 128, height: 80, paddingLeft: 24, paddingRight: 24, borderLeft: '1px #D0D1D1 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'inline-flex' }}>
                <div onClick={handleLogout} style={{ textAlign: 'center', cursor: "pointer", color: '#001B30', fontSize: 18, fontWeight: '700', letterSpacing: 0.48, wordWrap: 'break-word' }}>
                  <MdExitToApp style={{ color: 'black', fontSize: 25 }} />Sair
                </div>
              </div>
            </div>
          </div>
        </Drawer>
        {planType && planType === "free_trial" && (
          <div className="info-licenca">
            <span>Dias de teste restantes: {daysToExpire <= 0 ? 0 : daysToExpire} dias</span>
          </div>
        )}
        {planType && planType !== "free_trial" && daysToExpire <= 3 && (
          <div className="info-licenca">
            <span>
              Faltam apenas {daysToExpire <= 0 ? 0 : daysToExpire} dias para seu plano expirar
            </span>
          </div>
        )}
        <SidebarNav
          ref={leftMenuRef}
          sidebar={sidebar}
          className={sidebar ? "open" : "closed"}
          style={{ boxShadow: "1px 1px 6px #0000001c" }}
        >
          <SidebarWrap>
            <div className="collapseDiv">
              {sidebar ? (
                <RiMenuFoldFill
                  onClick={toggleSidebar}
                  style={{
                    color: "rgb(240,240,240)",
                    cursor: "pointer",
                    position: "absolute",
                    fontSize: "20px",
                    marginRight: "5px",
                    marginTop: "4px",
                  }}
                />
              ) : (
                <RiMenuUnfoldFill
                  onClick={toggleSidebar}
                  style={{
                    color: "rgb(60,60,60)",
                    cursor: "pointer",
                    position: "absolute",
                    fontSize: "20px",
                    marginRight: "5px",
                    marginTop: "4px",
                  }}
                />
              )}
            </div>

            {sidebar ? (
              <div
                style={{
                  borderBottom: "3px solid #4281FF",
                  backgroundImage: `url(${userDiv})` /*background:"rgba(0,0,0,0.8)"*/,
                }}
              >
                <div
                  className="userCircleImg"
                  style={{ paddingTop: "4px", paddingBottom: "2px" }}
                >
                  {/*<HiIcons.HiUserCircle style={{color:"rgb(200,200,200)", fontSize:"60px", padding:"4px"}}/>
                <img style={{backgroundImage:`url(${userImg})`}}/>*/}
                  <img
                    src={
                      userImg !== null && userImg.length > 0
                        ? userImg
                        : defaultUserImg
                    }
                    width={60}
                    height={60}
                    className="userImg"
                  />
                </div>
                <NavIcon /*to='#'*/ style={{ marginLeft: "30px" }}>
                  <label
                    style={{
                      maxWidth: "80%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {userName}
                  </label>
                  <div ref={userOptionsRef}>
                    <HiIcons.HiOutlineDotsVertical
                      onClick={showUserOptions}
                      style={{
                        color: "white",
                        cursor: "pointer",
                        marginLeft: "10px",
                        fontSize: "22px",
                      }}
                    />
                    <ModalUserOptions showOptions={showOptions}>
                      <li
                        onClick={(e) => handleEdit(userID)}
                        style={{ cursor: "pointer" }}
                      >
                        <a>Editar Perfil</a>
                      </li>
                      <li
                        onClick={showModalAddUserImg}
                        style={{ cursor: "pointer" }}
                      >
                        <a>Editar Imagem</a>
                      </li>
                      <li onClick={handleLogout} style={{ cursor: "pointer" }}>
                        <a>Sair</a>
                      </li>
                    </ModalUserOptions>
                  </div>
                </NavIcon>
                <div
                  style={{
                    paddingBottom: "2px",
                    justifyContent: "center",
                    display: "flex",
                    fontSize: "12px",
                    color: "white",
                  }}
                >
                  {userEmail}
                </div>
              </div>
            ) : (
              <div
                style={{
                  boxShadow: "-1px 1px 1px 0px #0000001c",
                  borderBottom: "3px solid transparent",
                }}
              >
                <div
                  className="userCircleImg"
                  style={{
                    paddingTop: 15,
                    paddingBottom: 5,
                    paddingRight: 3,
                    paddingBottom: 11,
                  }}
                >
                  {/*<HiIcons.HiUserCircle style={{color:"rgb(180,180,180)", fontSize:"108px", padding:"4px"}}/>*/}
                  <img
                    src={
                      userImg !== null && userImg.length > 0
                        ? userImg
                        : defaultUserImg
                    }
                    width={80}
                    height={80}
                    className="userImg"
                    style={{ padding: "0px" }}
                  />
                </div>
                <NavIcon
                  to="#"
                  style={{
                    /*display:"none",*/ fontSize: "0px",
                    height: "0px" /*visibility:"hidden"*/,
                  }}
                >
                  <label
                    style={{
                      maxWidth: "80%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {userName}
                  </label>
                  <div ref={userOptionsRef} style={{ display: "none" }} />
                </NavIcon>
                <div
                  style={{
                    paddingBottom: "10px",
                    justifyContent: "center",
                    display: "flex",
                    fontSize: "12px",
                    display: "none",
                  }}
                >
                  {userEmail}
                </div>
              </div>
            )}

            <div
              className="boxLeftMenuScroll"
              style={{ overflowY: "scroll", height: "calc(100vh - 120px)" }}
            >
              {SidebarData.map((item, index) => {
                return (
                  <PermissionGate
                    key={index}
                    permissions={[SidebarData[index].permission]}
                  >
                    <SubMenu
                      item={item}
                      key={index}
                      style={{ background: "black" }}
                    />
                  </PermissionGate>
                );
              })}
            </div>
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default LeftMenu;
