import styled from "styled-components";
import React from 'react'
import InputMask from 'react-input-mask'
import PermissionGate from "../../services/PermissionGate";

import { useFormik, Field } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai';
import { updateCategorias } from "../../services/api";
import cep from 'cep-promise'

import { AuthContext } from "../../contexts/auth";
import { useEffect, useContext, useState } from "react";

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { BsPlusCircle } from "react-icons/bs";
import { FaTrash } from 'react-icons/fa'

const DivEditCategoriaContainer = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showEditCategoria }) => (showEditCategoria ? 'none' : 'flex')};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
`;

const DivEditCategoria = styled.div`
    z-index: 20;
    position: absolute;
    top: 15%;
    left: 18%;
    float: left;
    min-width: 60%;
    min-height: 50%;
    max-width: 60%;
    max-height: 80%;
    padding: 10px 10px;
    list-style: none;
    --background-color: white;
    background: rgb(247,247,247)!important;
    background-clip: padding-box;
    border-radius: 15px;
`;


const options = [
    { value: "sempre", label: "Sempre disponível" },
    { value: "pausado", label: "Pausado e não disponível no cardápio" },
    { value: "especificos", label: "Disponível em dias e horários específicos" }
];

const Days = [{ domingo: "D", segunda: "S", terca: "T", quarta: "Q", quinta: "Q", sexta: "S", sabado: "S" }];

const CustomCheckbox = ({ checked, onChange, value, dayString, disabled }) => {
    return (
        <div
            className={`custom-checkbox ${checked ? 'checked' : ''}`}
            onClick={() => onChange(value)}
        >
            {checked ? <p className="fas fa-check">{dayString}</p> : <p>{dayString}</p>}
        </div>
    );
}

const optionsModelo = [
    { value: 1, label: "Itens Principais" },
    { value: 2, label: "Pizza" }
];

const ModalEditCategoria = ({ setEditCategoria, showEditCategoria, setRefresh,
    _idCategoriaToEdit,
    id_categoria,
    _modeloToEdit,
    _titleToEdit,
    _disponibilidadeToEdit,
    setDisponibilidadeToEdit,
    _diaHorarioDispToEdit,
    setDiaHorarioDispToEdit
}) => {
    const { user } = useContext(AuthContext);
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;

    const [modelo, setModelo] = useState(null)
    // Initialize diaHorarioDisp state with an array that contains an object with an empty Set for the dias property
    const [diaHorarioDisp, setDiaHorarioDisp] = useState([
        {
            dias: new Set(),
            startTime: "00:00",
            endTime: "23:59"
        }
    ]);

    /*class SerializableSet extends Set {
        toJSON() {
          return Array.from(this);
        }
    }*/

    const unificaDiasIguais = (array) => {
        const teste = array.reduce((acc, curr) => {
            const prevItem = acc[acc.length - 1];
            if (
                prevItem &&
                prevItem.startTime === curr.startTime &&
                prevItem.endTime === curr.endTime
            ) {
                prevItem.dias = new Set([...prevItem.dias, ...curr.dias]);
            } else {
                acc.push(curr);
            }
            return acc;
        }, []);
        return teste;
    }

    // useEffect para carregar os dados de dias e horários já carregados
    useEffect(() => {
        if (_diaHorarioDispToEdit && _diaHorarioDispToEdit.length > 0) {
            setDiaHorarioDispToEdit(unificaDiasIguais(_diaHorarioDispToEdit));
        }
    }, []); // add an empty dependency array here

    // função para atualizar o estado dos dias e horários do formulário
    const updateDiaHorarioDisp = (newDiaHorarioDisp) => {
        setDiaHorarioDispToEdit(newDiaHorarioDisp);
    };

    // função para lidar com as mudanças nos dias e horários do formulário
    const handleDiaHorarioDispChange = (currentHorarioIndex, key) => {
        const newDiasHorarios = [..._diaHorarioDispToEdit];
        const horario = newDiasHorarios[currentHorarioIndex];

        if (horario) {
            /*if (horario.dias.has(key)) {
              horario.dias.delete(key);
            } else {
              horario.dias.add(key);
            }*/

            if (horario.dias.has(key)) {
                if (horario.dias.size > 1) {
                    horario.dias.delete(key)
                } else {
                    // horario.dias.set(key, "")
                    horario.dias.clear()
                    horario.dias.add("")
                }
            } else (
                horario.dias.add(key)
            )

            console.log("newDiasHorarios>", newDiasHorarios);
            updateDiaHorarioDisp(newDiasHorarios);
            /*if(horario.dias.size===0){
                updateDiaHorarioDisp([
                    {
                    ...horario, // manter as outras propriedades do objeto
                    dias: new Set([""]),
                    }
                ])
            } else {
                updateDiaHorarioDisp(newDiasHorarios);
            } */

        }

    };

    useEffect(() => {
        if (_modeloToEdit) {
            console.log("PASSOU 1");
            setModelo(_modeloToEdit.value);  // Aqui, apenas o valor do objeto é usado
            formik.setFieldValue("modeloFormik", _modeloToEdit.value); // Só o value é enviado
        } else {
            console.log("PASSOU 2");
            setModelo(optionsModelo[0].value); // Apenas o value
            formik.setFieldValue("modeloFormik", optionsModelo[0].value);
        }
    }, [showEditCategoria]);

    //Validações
    const SignUpSchema = Yup.object().shape({
        modeloFormik: Yup.mixed().required("Obrigatório"),
        title: Yup.string().required("Obrigatório"),
    });



    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: true,
        //enableReinitialize: showEditCategoria,
        initialValues: {
            modeloFormik: modelo,
            title: _titleToEdit,
            disponibilidadeToEdit: _disponibilidadeToEdit
        },
        onSubmit: (values) => {
            //alert(JSON.stringify(values, null, 2));
            //console.log(values);
            //const modeloFormik = values.modeloFormik;
            const title = values.title;
            const disponibilidade = values.disponibilidadeToEdit;

            const horariosPorDia = _diaHorarioDispToEdit.reduce((acc, { dias, startTime, endTime }) => {
                dias.forEach(dia => {
                    if (!acc[dia]) {
                        acc[dia] = {
                            dia,
                            startTime,
                            endTime,
                        };
                    } else {
                        const { startTime: startTimeAcc, endTime: endTimeAcc } = acc[dia];
                        const [startTimeHour, startTimeMin] = startTime.split(':').map(Number);
                        const [startTimeAccHour, startTimeAccMin] = startTimeAcc.split(':').map(Number);
                        const [endTimeHour, endTimeMin] = endTime.split(':').map(Number);
                        const [endTimeAccHour, endTimeAccMin] = endTimeAcc.split(':').map(Number);

                        if (startTimeHour < startTimeAccHour || (startTimeHour === startTimeAccHour && startTimeMin < startTimeAccMin)) {
                            acc[dia].startTime = startTime;
                        }

                        if (endTimeAccHour < endTimeHour || (endTimeAccHour === endTimeHour && endTimeAccMin < endTimeMin)) {
                            acc[dia].endTime = endTime;
                        }
                    }
                });

                return acc;
            }, {});

            const horariosUnicos = Object.values(horariosPorDia).filter(horario => horario.dia !== '');
            console.log("horariosUnicos", horariosUnicos)


            handleSubmitAPI(horariosUnicos, title, disponibilidade);

        },
    });

    const handleSubmitAPI = (horariosUnicos, title, disponibilidade) => {
        const userID = user._id;
        //console.log(userID);
        //console.log(_diaHorarioDispToEdit)
        // Encontrar o objeto completo a partir do value
        const modeloSelecionado = optionsModelo.find(option => option.value === modelo);
        console.log("submit>>", userID, _idCategoriaToEdit, id_categoria, idEmpresa, modeloSelecionado, title, disponibilidade, horariosUnicos);

        updateCategorias(userID, _idCategoriaToEdit, id_categoria, idEmpresa, modeloSelecionado, title, disponibilidade, horariosUnicos).then(cast => {
            console.log("Tempo de entrega editado com Sucesso!:::", cast)
            if (cast.status === 200) {
                toast(cast.data.msg, { autoClose: 5000, type: "success" });
                //navigate("/list-users");
                setRefresh(true);
                setEditCategoria(!showEditCategoria);
            } else {
                toast(cast.data.msg, { autoClose: 5000, type: "error" });
            }
        }).catch(err => {
            console.log('Ocorreu algum erro no servidor!', err);
            //console.log(err.response.data.msg);
            toast(err.response.data.msg, { autoClose: 5000, type: "error" });
        });

    }


    const closeEditCategoria = () => setEditCategoria(!showEditCategoria);

    const handleDeleteHorario = (horarioIndex) => {
        setDiaHorarioDispToEdit((prevDiasHorarios) => {
            const newDiasHorarios = [...prevDiasHorarios];
            newDiasHorarios.splice(horarioIndex, 1);
            return newDiasHorarios;
        });
    };


    //const [selectedOption, setSelectedOption] = useState(_disponibilidadeToEdit);  
    const handleOptionChange = (event) => {
        //setSelectedOption(event.target.value);
        if (_disponibilidadeToEdit === "sempre" || _disponibilidadeToEdit === "pausado") {
            setDiaHorarioDispToEdit([
                {
                    dias: new Set([""]),
                    startTime: "00:00",
                    endTime: "23:59",
                }
            ]);
        }
        formik.setFieldValue("disponibilidadeToEdit", event.target.value);
    };

    const validateTime = (value) => {
        const regex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
        return regex.test(value);
    };
    const handleTimeChange = (horarioIndex, setTime, event) => {
        const value = event.target.value;
        if (validateTime(value)) {
            const newHorarios = [..._diaHorarioDispToEdit];
            newHorarios[horarioIndex][setTime] = value;
            setDiaHorarioDispToEdit(newHorarios);
        }
    };

    const handleAddHorario = () => {
        setDiaHorarioDispToEdit(_diaHorarioDispToEdit.concat({
            dias: new Set([""]),
            startTime: "00:00",
            endTime: "23:59",
        }));
    };

    const isDayAlreadySelectedInHorario = (day, currentHorarioIndex) => {
        const currentHorarioDays = diaHorarioDisp[currentHorarioIndex]?.dias || new Set();
        const allDays = new Set(Object.keys(Days[0]));
        allDays.delete(day);
        return currentHorarioDays.has(day) || !allDays.has(day);
    };




    return (
        <DivEditCategoriaContainer showEditCategoria={showEditCategoria}>
            <DivEditCategoria>

                <div className="w-100 p-5" style={{ width: "96%", marginLeft: "2%!important" }}>
                    <div style={{ marginBottom: "20px", fontSize: "25px" }}>
                        <a>Editar Categoria</a>
                    </div>

                    {/* ///////////////////////////////// */}
                    <form onSubmit={formik.handleSubmit} style={{ maxWidth: "100%" }}>

                        <div className="flex-column flex-md-row gap-2" style={{ display: "flex", justifyContent: "right" }}>
                            <div className="continue-button" style={{ width: "150px", display: "flex", justifyContent: "right" }}>
                                <button style={{ marginTop: "12px", height: "49.6px", width: "150px" }} type="submit">
                                    <a><AiIcons.AiOutlineSave style={{ marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} />Salvar</a>
                                </button>
                            </div>
                            <div className="back-button" style={{ textAlign: "start" }}>
                                <button onClick={closeEditCategoria} type="button">
                                    <AiIcons.AiOutlineCloseCircle style={{ color: "#ff4c4c", marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} /><a>Fechar</a>
                                </button>
                            </div>
                        </div>

                        <div className="input-group-edit modalCategoria flex-column flex-md-row gap-2" style={{ paddingBottom: "0px", marginBottom: "0px", justifyContent: "start" }}>

                            <div className="input-box" style={{ width: "29%" }}>
                                <label>Tipo do grupo</label>
                                {/*<!-- Custom select structure --> */}
                                <div className="selectdiv">
                                    <label style={{ width: "100%" }}>
                                        <select
                                            id="modeloFormik"
                                            type="text"
                                            name="modeloFormik"
                                            onChange={(e) => {
                                                //console.log("Valor selecionado: ", Number(e.target.value))
                                                setModelo(Number(e.target.value));
                                                formik.setFieldValue("modeloFormik", Number(e.target.value));
                                            }
                                            }
                                            value={modelo}
                                            // Desabilita o select e o torna como "readOnly"
                                            //disabled={true}  // Adicionando o atributo disabled
                                            style={{ width: "100%"/*, backgroundColor: "#f0f0f0", color: "#888"*/ }}  // Estilo para indicar que está desabilitado
                                            //className={calcular_maior_valor === "" ? 'invalid-option' : 'beleza'}
                                        >
                                            {optionsModelo.map((option) => (
                                                <option key={option.value} value={option.value} style={{ color: "black" }}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                    {formik.errors.modeloFormik && (
                                        <div className="error">{formik.errors.modeloFormik}</div>
                                    )}
                                </div>
                                {/*<!-- Custom select structure --> */}
                            </div>

                            <div className="input-box" style={{ width: "69%", paddingRight: "10px" }}>
                                <label>Nome da categoria<span style={{ color: "red" }}>*</span></label>
                                <input
                                    placeholder="Digite o nome da Categoria"
                                    id="title"
                                    type="title"
                                    name="title"
                                    onChange={formik.handleChange}
                                    value={formik.values.title}
                                />
                                {formik.errors.title && (
                                    <div className="error">{formik.errors.title}</div>
                                )}
                            </div>


                            <div className="disponibilidadeCategoria" style={{ width: "100%", marginLeft: "0", paddingRight: "10px" }}>
                                <div className="contentHeaderDisponibilidade">
                                    <h1 className="disponibilidadeH1">Disponibilidade<span style={{ color: "red" }}>*</span></h1>
                                    <div className="selectdiv disp" style={{width:"100%"}}>
                                        <label style={{ width: "100%" }}>
                                            <select id="disponibilidadeToEdit"
                                                type="disponibilidadeToEdit"
                                                name="disponibilidadeToEdit"
                                                value={formik.values.disponibilidadeToEdit} onChange={handleOptionChange}>
                                                {options.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select></label>
                                    </div>
                                </div>

                            </div>

                            <div className="contentInfoDisponibilidade">
                                {//_disponibilidadeToEdit === "especificos" && (
                                    formik.values.disponibilidadeToEdit === "especificos" && (
                                        <>
                                            {_diaHorarioDispToEdit.map((horario, horarioIndex) => (
                                                <div key={horarioIndex} className="divDiaHorario">
                                                    <div className="columnDia">
                                                        {horario.dias.size > 0 && (
                                                            <>
                                                                <h6>Dias disponíveis</h6>
                                                                <div>
                                                                    {Days[0] &&
                                                                        Object.entries(Days[0]).map(([key, value]) => (
                                                                            <label key={key}>
                                                                                <CustomCheckbox
                                                                                    checked={horario.dias.has(key)}
                                                                                    onChange={() => handleDiaHorarioDispChange(horarioIndex, key)}
                                                                                    value={key}
                                                                                    dayString={value}
                                                                                    horario={horario}
                                                                                    selectedDays={horario.dias}
                                                                                />
                                                                            </label>
                                                                        ))}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="columnHorario">
                                                        <h6>Horários</h6>
                                                        <div key={horarioIndex} className="divTimeSet">
                                                            <input
                                                                type="time"
                                                                value={horario.startTime}
                                                                onChange={(event) => handleTimeChange(horarioIndex, "startTime", event)}
                                                            />
                                                            <span> às </span>
                                                            <input
                                                                type="time"
                                                                value={horario.endTime}
                                                                onChange={(event) => handleTimeChange(horarioIndex, "endTime", event)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="columnDeleteHorario">
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            onClick={() => handleDeleteHorario(horarioIndex)}
                                                        >
                                                            <FaTrash size={14} color="white" />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                            <button type="button" className="buttonAddHorarios" onClick={handleAddHorario}><BsPlusCircle /> Adicionar Horário</button>
                                        </>
                                    )}
                            </div>


                        </div>


                    </form>
                    {/* ////////////////////////////////////   */}



                    {/* <a className="btn btn-sm btn-danger" onClick={closeModelImg} style={{color:"rgb(120,120,120)", background:"white"}}>Fechar</a> */}

                </div>
                {/* Fim Upload Images */}


            </DivEditCategoria>
        </DivEditCategoriaContainer>
    )
}

export default ModalEditCategoria;