import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import imgTeste from '../../img/imgTeste.png'
import './styleMobile.css'
import ModalItemAdicionado from "../../components/ModalItemAdicionado";
import CryptoJS from 'crypto-js';
import { toast } from "react-toastify";
import { FaPix } from "react-icons/fa6";

const InfoLoja = () => {

    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';

    const location = useLocation()
    const infoLoja = location.state.infoEmpresa;
    //const itensAlreadyLoaded = location.state.itensAlreadyLoaded;
    const { nomeEmpresaForUrl, idEmpresa } = useParams();
    console.log(infoLoja)
    const navigate = useNavigate();
    // Obtém o external_id da URL atual, se existir
    const [searchParams] = useSearchParams();
    const externalId = searchParams.get('external_id');

    /*const handleBack = () => {
        navigate('/cardapio/'+nomeEmpresaForUrl+'/'+idEmpresa);
    }*/
    const handleBack = () => {
        if (externalId) {
            // Se external_id existir, inclui ele na navegação
            navigate(`/cardapio-salao/${nomeEmpresaForUrl}/${idEmpresa}?external_id=${externalId}`);
        } else {
            // Caso contrário, navega sem external_id
            navigate(`/cardapio-salao/${nomeEmpresaForUrl}/${idEmpresa}`);
        }
    };

    const daysOfWeek = ["domingo", "segunda", "terca", "quarta", "quinta", "sexta", "sabado"];

    function formatTime(time) {
        const [hours, minutes] = time.split(':');
        return `${parseInt(hours)}h${minutes}m`;
    }

    function formatOperatingHours(hours) {
        return hours.map(period => `${formatTime(period.start)} às ${formatTime(period.end)}`).join(' - ');
    }
    
    const formattedSchedule = daysOfWeek.map(day => {
        const hours =  infoLoja.horario_funcionamento ? infoLoja.horario_funcionamento[day] : null;
        const schedule = hours ? formatOperatingHours(hours) : infoLoja.status_loja==='sempre' ? 'Sempre Aberto' : 'Fechado';
        return `${day.charAt(0).toUpperCase() + day.slice(1)}: ${schedule}`;
    });

    //console.log("horario_funcionamento:",infoLoja.horario_funcionamento)
    //console.log(formattedSchedule)

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    return (

        <div className="flex flex-col max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden md:max-w-2xl" style={{height:"100vh"}}>
            
            <div className="flex flex-col max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden md:max-w-2xl">
                <div className="carrinhoHeader">    
                    <div className="backButtonCarrinho" onClick={()=>handleBack()}>            
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={28}
                        height={28}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-chevron-left"
                        >
                        <polyline points="15 18 9 12 15 6" />
                        </svg>                      
                    </div>
                    <span>Sobre o estabelecimento</span> 
                </div>
            </div>
    

            <div className="p-4" style={{fontSize:20, fontWeight:700}}>
                {infoLoja.name}
            </div>            

            <div className="observacoesDetalhe">
                <div className="obsTextBox">
                    <div className="obsText">
                        <span>Endereço</span>
                    </div>
                </div>                            
            </div>
            <div className="infoBoxContainer">
                <span>{`${infoLoja.logradouro}, ${infoLoja.address_number} - ${infoLoja.bairro}, ${infoLoja.municipio} - ${infoLoja.estado}, ${infoLoja.cep}`}</span>
            </div>

            <div className="observacoesDetalhe">
                <div className="obsTextBox">
                    <div className="obsText">
                        <span>Formas de pagamento</span>
                    </div>
                </div>                            
            </div>
            <div className="infoBoxContainer">
                <div className="payment-option-stored">         
                    <div style={{display:'flex', gap:10, alignItems:'center'}}>
                        <FaPix style={{fontSize:30, color:'rgb(55,180,170)'}}/>                                  
                        <label htmlFor="Pix">Pix</label>  
                    </div>
        
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <div style={{display:'flex', gap:10, alignItems:'center'}}>
                            {svgMoney()}                                     
                            <label htmlFor="Dinheiro">Dinheiro</label>  
                        </div>
                    </div> 
                    <div style={{display:'flex', gap:10, alignItems:'center'}}>
                        {svgCreditCard()}                                     
                        <label htmlFor="Cartão">Cartão</label>  
                    </div>                                             
                </div>
            </div>

            <div className="observacoesDetalhe">
                <div className="obsTextBox">
                    <div className="obsText">
                        <span>Horário de atendimento</span>
                    </div>
                </div>                            
            </div>
            <div className="infoBoxContainer">
                <span>
                    {formattedSchedule.map((daySchedule, index) => (
                        <div key={index}>{daySchedule}</div>
                    ))}
                </span>
            </div>
   
        </div>

    );
}

export default InfoLoja;

const svgCreditCard = () => {
    return (
        <svg
            id="Layer_1"
            enableBackground="new 0 0 68 68"
            height={30}
            viewBox="0 0 68 68"
            width={30}
            xmlns="http://www.w3.org/2000/svg"
            >
            <g id="_x35_">
                <g>
                <g>
                    <g>
                    <g>
                        <path
                        d="m63.5814896 36.2649384-41.2045212 22.6769828c-2.2589283 1.243206-5.0979729.4197998-6.3411808-1.8391342l-12.5565136-22.8154182c-1.2432075-2.258934-.4198017-5.0979767 1.8391283-6.3411827l41.2045212-22.676981c2.2589302-1.243206 5.0979729-.4197984 6.3411789 1.8391328l12.5565147 22.8154221c1.2432089 2.2589297.4197989 5.0979723-1.8391275 6.3411784z"
                        fill="#febc1f"
                        />
                    </g>
                    </g>
                    <g>
                    <g>
                        <path
                        d="m62.6815987 34.6298218-41.2045212 22.676979c-2.2589283 1.243206-5.0979729.4197998-6.3411798-1.8391304l-12.5565146-22.815422c-1.2432075-2.2589302-.4198017-5.0979748 1.8391283-6.3411808l41.2045212-22.676981c2.2589302-1.2432063 5.0979729-.4197984 6.3411789 1.839133l12.5565176 22.8154214c1.2432022 2.2589302.4197998 5.0979748-1.8391304 6.3411808z"
                        fill="#ffd475"
                        />
                    </g>
                    </g>
                    <g>
                    <g>
                        <g>
                        <path
                            d="m45.6230049 3.6340563-41.2044864 22.6770265c-2.2589364 1.2432098-3.0823236 4.0822697-1.839144 6.3411503l12.556531 22.8154602s-11.7001696-22.3759117 34.6528778-51.9958496c-1.2919114-.5804889-2.829345-.5732968-4.1657784.1622126z"
                            fill="#febc1f"
                        />
                        </g>
                    </g>
                    </g>
                    <g>
                    <g>
                        <path
                        d="m6.2700973 35.4500999 1.5767589 2.8649979c.3974571.722187 1.3051071.9854317 2.0272923.5879745l43.4678116-23.9225845c.722187-.3974562.9854317-1.3051062.5879745-2.0272932l-1.5767593-2.8649998c-.3974563-.722187-1.3051063-.9854326-2.0272933-.5879764l-43.4678107 23.9225874c-.7221856.3974571-.9854307 1.3051071-.587974 2.0272941z"
                        fill="#1d2943"
                        />
                    </g>
                    </g>
                    <g>
                    <g>
                        <path
                        d="m5.8331218 34.656105 1.5767584 2.8650017c.3974571.7221832 1.3051066.9854317 2.0272927.5879745l43.4678116-23.9225883c.722187-.3974562.9854317-1.3051062.5879745-2.0272923l-1.5767593-2.8649998c-.3974571-.722187-1.3051071-.9854326-2.0272903-.5879765l-43.4678136 23.9225866c-.722186.3974571-.9854311 1.3051071-.587974 2.0272941z"
                        fill="#4c5c75"
                        />
                    </g>
                    </g>
                </g>
                <g>
                    <g>
                    <path
                        d="m56.1312866 64.9452744-47.0325269.0000228c-2.5784349 0-4.6686702-2.0902367-4.6686711-4.6686707l-.0000087-26.0424384c-.000001-2.578434 2.0902333-4.6686687 4.6686683-4.6686687l47.0325279-.0000191c2.578434 0 4.6686707 2.0902328 4.6686707 4.6686687l.0000076 26.0424385c-.0000009 2.578434-2.0902338 4.6686669-4.6686678 4.6686669z"
                        fill="#5094ff"
                    />
                    </g>
                </g>
                <g>
                    <g>
                    <path
                        d="m9.0987463 63.0788879 47.0325241.0000191c2.5784378 0 4.6686707-2.0902367 4.6686745-4.6686707l.0000076-26.0424347c0-2.5784359-2.0902328-4.6686726-4.6686668-4.6686726l-47.032528-.000019c-2.5784345 0-4.6686697 2.0902328-4.6686707 4.6686687l-.0000085 26.0424386c-.0000009 2.578434 2.0902329 4.6686706 4.6686678 4.6686706z"
                        fill="#57b3fe"
                    />
                    </g>
                </g>
                <g>
                    <g>
                    <g>
                        <path
                        d="m9.0987663 27.6990719 47.0325203.0000744c2.5784416 0 4.6686668 2.0902576 4.6686668 4.6686363l-.0000038 26.0424805s-.538311-25.2444992-55.4289742-28.8447552c.8519402-1.1314583 2.2023293-1.866436 3.7277909-1.866436z"
                        fill="#5094ff"
                        />
                    </g>
                    </g>
                </g>
                <g>
                    <g>
                    <g>
                        <g>
                        <path
                            d="m57.1417618 54.6996574v.7245789h-.0096512c.0188637.9798889-.7984848 1.8029976-1.7838097 1.8029976l-4.7369461.0000038c-.9678459 0-1.8029671-.8079567-1.7838097-1.8029976h-.0096511v-.7245789h.1157036c.2796631-.7038727 1.0414467-1.1859894 1.8416023-1.1859894l4.4091377-.0000038c.8002701 0 1.5620537.4821167 1.8417206 1.1859894z"
                            fill="#e3e7f0"
                        />
                        </g>
                    </g>
                    <g>
                        <g>
                        <path
                            d="m55.3433418 56.50811-4.5790215.0000687c-.9225769.0000114-1.7944107-.6264191-1.9239235-1.5398598-.1577072-1.1123047.7025833-2.0666733 1.7845535-2.0666847l4.5610123-.0000687c.9783401-.0000153 1.889431.709938 1.9490089 1.6864586.0661317 1.0839882-.7909735 1.9200745-1.7916297 1.9200859z"
                            fill="#fff"
                        />
                        </g>
                    </g>
                    </g>
                    <g>
                    <g>
                        <g>
                        <path
                            d="m43.5652237 54.6996613v.7245827h-.0096512c.0188637.9798851-.7984848 1.8029938-1.7838097 1.8029938l-4.7369461.0000038c-.9678459 0-1.8029671-.8079567-1.7838097-1.8029938h-.0096512v-.7245827h.1157036c.2796631-.7038727 1.0414467-1.1859894 1.8416023-1.1859894l4.4091377-.0000038c.8002701 0 1.5620537.4821167 1.8417206 1.1859894z"
                            fill="#e3e7f0"
                        />
                        </g>
                    </g>
                    <g>
                        <g>
                        <path
                            d="m41.7668037 56.5081139-4.5790253.0000687c-.9225769.0000153-1.7944069-.6264191-1.9239197-1.5398598-.1577072-1.1123009.7025833-2.0666695 1.7845535-2.0666809l4.5610123-.0000725c.9783363-.0000114 1.889431.7099419 1.9490089 1.6864624.0661317 1.0839843-.7909735 1.9200706-1.7916297 1.9200821z"
                            fill="#fff"
                        />
                        </g>
                    </g>
                    </g>
                    <g>
                    <g>
                        <g>
                        <path
                            d="m29.9886837 54.6996689v.7245789h-.0096512c.0188656.9798851-.7984829 1.8029976-1.7838097 1.8029976h-4.7369442c-.9678478 0-1.8029671-.8079529-1.7838097-1.8029938h-.0096531v-.7245827h.1157036c.279665-.7038689 1.0414486-1.1859894 1.8416023-1.1859894h4.4091377c.800272 0 1.5620556.4821167 1.8417206 1.1859894z"
                            fill="#e3e7f0"
                        />
                        </g>
                    </g>
                    <g>
                        <g>
                        <path
                            d="m28.1902657 56.5081177-4.5790234.0000725c-.9225769.0000114-1.7944088-.6264191-1.9239197-1.5398598-.1577091-1.1123047.7025814-2.0666733 1.7845497-2.0666847l4.5610161-.0000687c.9783363-.0000153 1.8894291.709938 1.9490051 1.6864586.0661316 1.0839844-.7909717 1.9200744-1.7916278 1.9200821z"
                            fill="#fff"
                        />
                        </g>
                    </g>
                    </g>
                    <g>
                    <g>
                        <g>
                        <path
                            d="m16.4121456 54.6996727v.7245827h-.0096512c.0188656.9798851-.7984838 1.8029938-1.7838097 1.8029938l-4.7369441.0000038c-.9678478 0-1.802968-.8079567-1.7838106-1.8029976h-.0096522v-.7245789h.1157036c.279665-.7038727 1.0414476-1.1859894 1.8416023-1.1859894l4.4091377-.0000038c.800272 0 1.5620556.4821167 1.8417206 1.1859894z"
                            fill="#e3e7f0"
                        />
                        </g>
                    </g>
                    <g>
                        <g>
                        <path
                            d="m14.6137276 56.5081253-4.5790243.0000687c-.9225769.0000114-1.7944078-.6264191-1.9239197-1.5398598-.1577077-1.1123009.7025814-2.0666733 1.7845497-2.0666847l4.5610161-.0000687c.9783363-.0000153 1.88943.709938 1.9490061 1.6864586.0661315 1.0839882-.7909708 1.9200745-1.7916279 1.9200859z"
                            fill="#fff"
                        />
                        </g>
                    </g>
                    </g>
                </g>
                <g>
                    <g>
                    <g>
                        <path
                        d="m8.0920725 35.925663.0000019 4.3564262c0 1.0981369.890214 1.9883499 1.988348 1.9883461l8.1573095-.0000229c1.098135 0 1.988348-.890213 1.988348-1.9883461l-.0000019-4.3564301c0-1.0981331-.8902149-1.9883461-1.988348-1.9883461l-8.1573105.0000229c-1.098134.0000039-1.988347.8902169-1.988347 1.98835z"
                        fill="#febc1f"
                        />
                    </g>
                    </g>
                    <g>
                    <g>
                        <path
                        d="m8.0920725 35.2468109.000001 4.3564262c.000001 1.0981331.890214 1.9883461 1.988348 1.9883461l8.1573105-.0000229c1.0981331 0 1.988348-.8902168 1.9883461-1.9883499v-4.3564262c-.0000019-1.0981331-.8902149-1.9883461-1.988348-1.9883461l-8.1573114.0000229c-1.0981342 0-1.9883472.890213-1.9883462 1.9883499z"
                        fill="#ffd475"
                        />
                    </g>
                    </g>
                </g>
                <g>
                    <g>
                    <g>
                        <path
                        d="m13.9477558 36.954998v-3.6965523h-.9444408v3.6965523h-4.9112435v.9444428h2.6869421v3.6921043h.9444418v-3.6921043h4.2524815v3.6921043h.9444065v-3.6921043h3.305748v-.9444428z"
                        fill="#febc1f"
                        />
                    </g>
                    </g>
                </g>
                <g>
                    <g>
                    <g>
                        <g>
                        <path
                            d="m56.2796402 40.2164574-5.3172913.0000305c-.443058.0000076-.8617401-.300827-.9239388-.7394943-.075737-.5341682.3374062-.9924927.8570061-.9924965l5.3086472-.0000343c.4698334-.0000076.9073715.3409386.9359818.8098984.0317611.5205688-.3798523.9220885-.860405.9220962z"
                            fill="#fff"
                        />
                        </g>
                    </g>
                    </g>
                    <g>
                    <g>
                        <g>
                        <path
                            d="m56.2796402 36.5089836-9.3353691.0000343c-.4430542.0000038-.8617401-.3008308-.9239349-.7394981-.075737-.5341682.3374023-.9924927.8570061-.9924965l9.3267212-.0000343c.4698334-.0000076.9073715.3409386.9359818.8099022.031761.5205688-.3798524.9220886-.8604051.9220924z"
                            fill="#fff"
                        />
                        </g>
                    </g>
                    </g>
                </g>
                </g>
                <g>
                <g>
                    <path
                    d="m54.8607178 16.3203678c2.2225723 2.8520584 4.0391045 5.9515629 5.4309731 9.2891464-2.225937-2.8498039-4.0357934-5.9533443-5.4309731-9.2891464z"
                    fill="#fff"
                    />
                </g>
                </g>
                <g>
                <g>
                    <path
                    d="m13.4443359 25.6074772c3.2388935-2.4783745 6.7269783-4.5519123 10.456749-6.2024593-3.2368565 2.4816837-6.7289753 4.5484409-10.456749 6.2024593z"
                    fill="#fff"
                    />
                </g>
                </g>
                <g>
                <g>
                    <path
                    d="m40.1918945 60.7349167c4.0498962-.4790192 8.107563-.4831581 12.1573486.0000458-4.0497855.4831161-8.1074485.4792175-12.1573486-.0000458z"
                    fill="#fff"
                    />
                </g>
                </g>
            </g>
        </svg>
    )
}

const svgMoney = () => {
    return (
        <svg
            id="Layer_1"
            enableBackground="new 0 0 50 50"
            height={30}
            viewBox="0 0 50 50"
            width={30}
            xmlns="http://www.w3.org/2000/svg"
            >
            <g>
                <g>
                <g>
                    <g>
                    <g enableBackground="new">
                        <g>
                        <path
                            d="m49 32.34555-.0152 4.5598-14.2916 8.3053.0152-4.5597z"
                            fill="#5eaf5f"
                        />
                        </g>
                        <g>
                        <path
                            d="m34.7084 40.65095-.0152 4.5597-33.6932-19.4527.0153-4.5597z"
                            fill="#7bc578"
                        />
                        </g>
                        <g>
                        <path
                            d="m49 32.34555-14.2916 8.3054-33.6931-19.4527 14.2916-8.3054z"
                            fill="#98d095"
                        />
                        </g>
                    </g>
                    </g>
                    <g>
                    <path
                        d="m36.7837 31.52105-.3891.2261c.3441.3097.3463.6854-.037.9082l-.5002-.2888c.1738-.101.0883-.3306-.1829-.4872-.2772-.16-.6738-.2088-.8476-.1078-.1735.1008-.0883.3306.1889.4907.5604.3235.6415.8.1864 1.0645-.3833.2228-1.0319.2227-1.5618.021l-.3892.2261-.5001-.2887.3891-.2262c-.3499-.3063-.3522-.682.0311-.9047l.5002.2888c-.1738.101-.0825.3271.1947.4872.2712.1566.662.2087.8358.1077.1796-.1044.088-.3304-.1832-.4869-.5604-.3236-.6472-.8038-.1921-1.0683.3833-.2227 1.0319-.2226 1.5677-.0243l.3891-.2262z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m17.2645 20.25165-.3892.2261c.3441.3097.3524.6889-.0309.9116l-.5062-.2923c.1737-.1009.0943-.3271-.1829-.4871-.2772-.1601-.6739-.2088-.8476-.1078-.1735.1008-.0884.3306.1889.4906.5604.3236.6475.8036.1924 1.068-.3833.2228-1.038.2193-1.5679.0176l-.3891.2261-.5002-.2888.3892-.2261c-.35-.3063-.3522-.682.0311-.9048l.5002.2888c-.1738.101-.0825.3272.1947.4873.2772.16.662.2087.8358.1077.1796-.1044.094-.3269-.1832-.487-.5605-.3236-.6472-.8037-.1921-1.0682.3833-.2227 1.0319-.2227 1.5676-.0244l.3892-.2261z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m16.6976 15.60325c.7654.4419.7694 1.1516.009 1.5936-.7542.4383-1.9858.4418-2.7511 0-.7593-.4384-.7632-1.1552-.009-1.5936.7604-.4419 1.9918-.4384 2.7511 0z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m29.9871 24.59375-.9637.5601c.857.7586.8624 1.684-.0897 2.2373l-1.2354-.7133c.431-.2505.2176-.8106-.4634-1.2038-.6749-.3897-1.6487-.5149-2.0797-.2645-.431.2505-.2116.8141.4634 1.2038 1.386.8003 1.5968 1.9763.4713 2.6304-.9459.5497-2.5497.5532-3.856.0557l-.9699.5636-1.2354-.7132.9699-.5637c-.8632-.755-.8684-1.6875.0775-2.2372l1.2354.7133c-.4311.2505-.2116.8141.4634 1.2038.6809.3931 1.6486.515 2.0797.2645.431-.2505.2176-.8107-.4634-1.2038-1.38-.7968-1.591-1.9797-.4713-2.6304.9521-.5533 2.5557-.5497 3.8682-.0558l.9637-.5601z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m8.4413 20.40135c.7653.4419.7693 1.1516.0092 1.5933-.7545.4385-1.9799.4385-2.7452-.0033-.7593-.4384-.7694-1.1517-.0148-1.5902.7601-.4417 1.9915-.4382 2.7508.0002z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m44.3102 31.54535c.7654.4419.7694 1.1517.009 1.5936-.7543.4383-1.9858.4419-2.7511 0-.7593-.4384-.7572-1.1517-.003-1.5901.7605-.4419 1.9858-.4419 2.7451-.0035z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m36.0539 36.34345c.7653.4419.7693 1.1517.0092 1.5934-.7545.4385-1.9799.4385-2.7452-.0034-.7593-.4384-.7634-1.1482-.0088-1.5867.7601-.4417 1.9854-.4417 2.7448-.0033z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m15.3202 14.19765 31.4272 18.1445-12.0461 7.0004-12.9445-7.4735-18.4827-10.671zm3.7006 16.05c3.3265 1.9205 8.7145 1.917 12.0134-.0001s3.2874-5.0381-.0391-6.9586c-3.3206-1.9171-8.7146-1.9171-12.0135 0s-3.2814 5.0416.0392 6.9587m-10.1546-8.0198 6.8157 3.9072c.2215-1.1759 1.1042-2.3172 2.6549-3.2184s3.5119-1.4125 5.5475-1.5483l-6.7916-3.9212c1.0056-.5844 1.0006-1.51.0123-2.0806-.9823-.5671-2.5863-.5705-3.5683.0001-.9819.5707-.9647 1.4962.0176 2.0633l-4.6756 2.7521c-1.0124-.5846-2.6164-.5879-3.5922-.0209-.9877.5741-.9705 1.4996.0118 2.0667.9883.5706 2.5801.5741 3.5679 0m27.595 13.8789 4.6816-2.7486c1.0124.5845 2.6042.588 3.5861.0174.982-.5707.977-1.4963-.0114-2.0669-.9822-.5671-2.5802-.567-3.5622.0037l-6.8153-3.9074c-.2338 1.1761-1.1103 2.3137-2.661 3.2149s-3.518 1.4161-5.5415 1.5518l6.7917 3.9212c-1.0118.588-1.0006 1.51-.0183 2.0771.9883.5706 2.5801.5741 3.5679.0001.9757-.5671.9707-1.4927-.0176-2.0633m-16.7862-18.7221-.892.5184 3.6399 2.1015.8919-.5184zm17.9162 10.344-.892.5183 3.6339 2.098.892-.5183zm-25.1609-1.9171.8861-.515-3.6398-2.1014-.8862.5149zm17.9102 10.3405.8862-.515-3.6339-2.098-.8861.515z"
                        fill="#46a046"
                    />
                    </g>
                </g>
                <g>
                    <g enableBackground="new">
                    <g>
                        <path
                        d="m35.7416 24.69085-14.2916 8.3053-.658-.3799 14.2916-8.3054z"
                        fill="#86aedc"
                        />
                    </g>
                    <g>
                        <path
                        d="m39.5801 26.90695-14.2916 8.3054-3.8385-2.2162 14.2916-8.3053z"
                        fill="#e3e6f0"
                        />
                    </g>
                    <g>
                        <path
                        d="m40.2342 27.28455-14.2916 8.3054-.6541-.3776 14.2916-8.3054z"
                        fill="#86aedc"
                        />
                    </g>
                    </g>
                </g>
                <g>
                    <g enableBackground="new">
                    <g>
                        <path
                        d="m21.4496 32.99595-.0299 4.5611-.6577-.3797.03-4.5611z"
                        fill="#678cc8"
                        />
                    </g>
                    <g>
                        <path
                        d="m25.285 35.21035-.03 4.561-3.8353-2.2143.0299-4.5611z"
                        fill="#d4dbe9"
                        />
                    </g>
                    <g>
                        <path
                        d="m25.9426 35.58995-.0299 4.5611-.6577-.3797.03-4.561z"
                        fill="#678cc8"
                        />
                    </g>
                    </g>
                </g>
                </g>
                <g>
                <g>
                    <g>
                    <g enableBackground="new">
                        <g>
                        <path
                            d="m49 24.24205-.0152 4.5597-14.2916 8.3054.0152-4.5597z"
                            fill="#5eaf5f"
                        />
                        </g>
                        <g>
                        <path
                            d="m34.7084 32.54745-.0152 4.5597-33.6932-19.4527.0153-4.5598z"
                            fill="#7bc578"
                        />
                        </g>
                        <g>
                        <path
                            d="m49 24.24205-14.2916 8.3054-33.6931-19.4528 14.2916-8.3053z"
                            fill="#98d095"
                        />
                        </g>
                    </g>
                    </g>
                    <g>
                    <path
                        d="m36.7837 23.41755-.3891.2261c.3441.3097.3463.6854-.037.9081l-.5002-.2887c.1738-.101.0883-.3306-.1829-.4872-.2772-.16-.6738-.2088-.8476-.1078-.1735.1008-.0883.3306.1889.4906.5604.3236.6415.8001.1864 1.0646-.3833.2227-1.0319.2227-1.5618.021l-.3892.2261-.5001-.2888.3891-.2261c-.3499-.3063-.3522-.682.0311-.9047l.5002.2887c-.1738.101-.0825.3272.1947.4873.2712.1565.662.2087.8358.1077.1796-.1044.088-.3304-.1832-.487-.5604-.3235-.6472-.8037-.1921-1.0682.3833-.2227 1.0319-.2227 1.5677-.0244l.3891-.2261z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m17.2645 12.14805-.3892.2262c.3441.3096.3524.6888-.0309.9116l-.5062-.2923c.1737-.101.0943-.3271-.1829-.4871-.2772-.1601-.6739-.2088-.8476-.1078-.1735.1008-.0884.3305.1889.4906.5604.3236.6475.8036.1924 1.068-.3833.2228-1.038.2192-1.5679.0175l-.3891.2262-.5002-.2888.3892-.2262c-.35-.3062-.3522-.6819.0311-.9047l.5002.2888c-.1738.101-.0825.3272.1947.4872.2772.1601.662.2088.8358.1078.1796-.1044.094-.327-.1832-.487-.5605-.3236-.6472-.8038-.1921-1.0682.3833-.2228 1.0319-.2227 1.5676-.0244l.3892-.2261z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m16.6976 7.49975c.7654.4419.7694 1.1516.009 1.5935-.7542.4383-1.9858.4419-2.7511.0001-.7593-.4384-.7632-1.1553-.009-1.5936.7604-.4419 1.9918-.4384 2.7511 0z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m29.9871 16.49025-.9637.5601c.857.7585.8624 1.684-.0897 2.2372l-1.2354-.7132c.431-.2505.2176-.8106-.4634-1.2038-.6749-.3897-1.6487-.515-2.0797-.2645s-.2116.8141.4634 1.2038c1.386.8002 1.5968 1.9763.4713 2.6304-.9459.5497-2.5497.5532-3.856.0557l-.9699.5636-1.2354-.7132.9699-.5637c-.8632-.755-.8684-1.6875.0775-2.2372l1.2354.7133c-.4311.2505-.2116.8141.4634 1.2038.6809.3931 1.6486.5149 2.0797.2644.431-.2505.2176-.8106-.4634-1.2037-1.38-.7968-1.591-1.9798-.4713-2.6304.9521-.5533 2.5557-.5498 3.8682-.0558l.9637-.5601z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m8.4413 12.29785c.7653.4418.7693 1.1516.0092 1.5933-.7545.4385-1.9799.4385-2.7452-.0034-.7593-.4383-.7694-1.1516-.0148-1.5901.7601-.4417 1.9915-.4382 2.7508.0002z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m44.3102 23.44185c.7654.4419.7694 1.1517.009 1.5936-.7543.4383-1.9858.4419-2.7511 0-.7593-.4384-.7572-1.1518-.003-1.5901.7605-.4419 1.9858-.4419 2.7451-.0035z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m36.0539 28.23995c.7653.4419.7693 1.1516.0092 1.5933-.7545.4386-1.9799.4385-2.7452-.0033-.7593-.4384-.7634-1.1482-.0088-1.5867.7601-.4417 1.9854-.4417 2.7448-.0033z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m15.3202 6.09405 31.4272 18.1446-12.0461 7.0004-12.9445-7.4735-18.4827-10.671zm3.7006 16.05c3.3265 1.9206 8.7145 1.9171 12.0134 0s3.2874-5.0381-.0391-6.9587c-3.3206-1.9171-8.7146-1.917-12.0135.0001s-3.2814 5.0415.0392 6.9586m-10.1546-8.0197 6.8157 3.9072c.2215-1.176 1.1042-2.3172 2.6549-3.2184s3.5119-1.4126 5.5475-1.5483l-6.7916-3.9212c1.0056-.5844 1.0006-1.51.0123-2.0806-.9823-.5672-2.5863-.5705-3.5683.0001-.9819.5707-.9647 1.4962.0176 2.0633l-4.6756 2.752c-1.0124-.5845-2.6164-.5878-3.5922-.0208-.9877.574-.9705 1.4995.0118 2.0667.9883.5706 2.5801.574 3.5679 0m27.595 13.8789 4.6816-2.7486c1.0124.5845 2.6042.588 3.5861.0174.982-.5707.977-1.4963-.0114-2.0669-.9822-.5671-2.5802-.567-3.5622.0036l-6.8153-3.9074c-.2338 1.1761-1.1103 2.3138-2.661 3.215-1.5507.9011-3.518 1.4161-5.5415 1.5518l6.7917 3.9211c-1.0118.5881-1.0006 1.5101-.0183 2.0772.9883.5706 2.5801.5741 3.5679 0 .9757-.567.9707-1.4926-.0176-2.0632m-16.7862-18.7221-.892.5184 3.6399 2.1014.8919-.5183zm17.9162 10.3439-.892.5184 3.6339 2.098.892-.5183zm-25.1609-1.917.8861-.515-3.6398-2.1015-.8862.515zm17.9102 10.3405.8862-.515-3.6339-2.098-.8861.5149z"
                        fill="#46a046"
                    />
                    </g>
                </g>
                <g>
                    <g enableBackground="new">
                    <g>
                        <path
                        d="m35.7416 16.58735-14.2916 8.3053-.658-.3799 14.2916-8.3054z"
                        fill="#86aedc"
                        />
                    </g>
                    <g>
                        <path
                        d="m39.5801 18.80345-14.2916 8.3054-3.8385-2.2162 14.2916-8.3053z"
                        fill="#e3e6f0"
                        />
                    </g>
                    <g>
                        <path
                        d="m40.2342 19.18105-14.2916 8.3054-.6541-.3776 14.2916-8.3054z"
                        fill="#86aedc"
                        />
                    </g>
                    </g>
                </g>
                <g>
                    <g enableBackground="new">
                    <g>
                        <path
                        d="m21.4496 24.89245-.0299 4.5611-.6577-.3797.03-4.5611z"
                        fill="#678cc8"
                        />
                    </g>
                    <g>
                        <path
                        d="m25.285 27.10675-.03 4.5611-3.8353-2.2143.0299-4.5611z"
                        fill="#d4dbe9"
                        />
                    </g>
                    <g>
                        <path
                        d="m25.9426 27.48645-.0299 4.5611-.6577-.3797.03-4.5611z"
                        fill="#678cc8"
                        />
                    </g>
                    </g>
                </g>
                </g>
            </g>
        </svg>
    )
}