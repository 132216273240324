import React, { useState, useRef, useEffect, useContext } from "react";
import { useLocation } from 'react-router-dom';
import './style.css'
import styled from 'styled-components';
//import InputMask from 'react-input-mask'
//import CurrencyInput from 'react-currency-input-field';
import ImageUploading from 'react-images-uploading';
import { CImage } from '@coreui/react'
//import Image from 'react-image'
//import Gallery from 'react-image-gallery'
import CryptoJS from 'crypto-js';
import AsyncSelect from 'react-select/async';
import { AuthContext } from "../../contexts/auth";
import { SidebarContext } from "../../AppRoutes";
import { getAdicionais, getCategorias, getItem, updateItens } from "../../services/api";
import ModalAddGroupAdicionais from "./ModalAddGroupAdicionais";
import ModalAddGroupSabores from "./ModalAddGroupSabores";
import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";

import InputMoney from "./InputMoney";

import { useFormik } from "formik";
import * as Yup from "yup";
import * as FiIcons from 'react-icons/fi'
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import { MdFastfood } from "react-icons/md";
import { BsPlusCircle } from "react-icons/bs";
import { FaTrash } from 'react-icons/fa'
import { IoArrowRedo } from "react-icons/io5";
import { IoArrowUndo } from "react-icons/io5";
import { toast } from "react-toastify";
import { IoIosAddCircleOutline } from "react-icons/io";

import { FaRegEdit } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { TbDotsVertical } from "react-icons/tb";

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;


const EditItem = () => {
    const location = useLocation();
    const { itemObjId, toWhere, modelo } = location.state || {};

    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    const userName = userParse.name;
    const userEmail = userParse.email;
    var userImg = null;
    //console.log(empresa)
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;
    const objIdEmpresa = empresaParse._id;
    const vinculo_empresa = empresaParse.cnpj;

    const [withAdicional, setWithAdicional] = useState(false);
    const [etapaAtiva, setEtapaAtiva] = useState(toWhere === "adicional" ? 2 : toWhere === "sabor" ? 3 : 1);
    const descriptionRef = useRef();
    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES
    const [orderItem, setOrderItem] = useState(0);
    const [valuePreco, setValuePreco] = useState(0);
    const [showModalAddGroupAdd, setShowModalAddGroupAdd] = useState(true);
    const [showModalSaboresGroupAdd, setShowModalSaboresGroupAdd] = useState(true);
    const [tempSabores, setTempSabores] = useState([]);
    const [tempAdicionais, setTempAdicionais] = useState([]);

    const [refresh, setRefresh] = useState(false);
    const [imagesToString, setImagesToString] = React.useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedDays, setSelectedDays] = useState([]);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [diasHorarios, setDiasHorarios] = useState([
        {
            dias: new Set(),
            startTime: "00:00",
            endTime: "23:59",
        }
    ]);

    const [item, setItem] = useState({})
    const [adicionais, setAdicionais] = useState([]);
    const [sabores, setSabores] = useState([]);

    const INITIAL_DATA = {
        value: "",
        label: 'Selecione a categoria',
    };
    const [selectData, setselectData] = useState(INITIAL_DATA);


    // Inicializa como um array vazio para múltiplas seleções
    const [selectDataAdicionais, setselectDataAdicionais] = useState([]);
    const [selectDataSabores, setSelectDataSabores] = useState([]);

    const mapResponseToValuesAndLabels = (data) => ({
        value: data._id,
        label: data.title,
    });

    async function callApi(value) {

        const data = await getCategorias(userID, idEmpresa, vinculo_empresa, objIdEmpresa)
            .then((response) => response.data.categorias)//response.json())
            .then((response) => {
                // Aplica o filtro de acordo com o valor de modeloFiltro
                if (modelo?.value === 2) {
                    //console.log("Pizza:", JSON.stringify(response));
                    return response.filter((categoria) => categoria.modelo?.value === 2); // Filtro para 'modelo === 2'
                } else if (modelo?.value === 1 || !modelo) {
                    //console.log("Simples:", response);
                    return response.filter((categoria) => categoria.modelo?.value === 1 || !categoria.modelo); // Filtro para 'modelo === 1' ou vazio
                }
                return response; // Se nenhum filtro for aplicado, retorna todas as categorias
            })
            .then((response) => response.sort((a, b) => a.order - b.order)) // Adicione esta linha para ordenar os dados
            .then((response) => response.map(mapResponseToValuesAndLabels))
            .then((response) => response.filter((i) => i.label.toLowerCase().includes(value?.toLowerCase())));

        return data;
    }

    // Função de chamada de API ajustada, se necessário
    /*async function callApiAdicionais(value) {
        const data = await getAdicionais(userID, idEmpresa, vinculo_empresa, objIdEmpresa)
            .then((response) => response.data.adicionais)
            .then((response) => response.map(mapResponseToValuesAndLabels))
            .then((response) => response.filter((i) => i.label.toLowerCase().includes(value.toLowerCase())));

        setAdicionais(data);
        console.log("JSON.stringify(data):::", JSON.stringify(data))
        return data;
    }*/
    async function callApiAdicionais(value) {
        // Passo 1: Obter os dados completos da API
        const data = await getAdicionais(userID, idEmpresa, vinculo_empresa, objIdEmpresa)
            .then((response) => response.data.adicionais) // Dados completos de 'adicionais'
            .then((response) => {
                // Aplica o filtro de acordo com o valor de type
                return response.filter((categoria) => categoria.type === "simples" || !categoria.type);
            })

        // Passo 2: Salvar os dados completos em um estado para referência futura
        //console.log("DATA ADICIONAIS:::", data);
        setAdicionais(data); // Esse estado vai armazenar todos os dados dos adicionais sem filtros

        // Passo 3: Combinar sabores temporários com os dados da API
        const combinedAdicionais = [
            ...data.map(mapResponseToValuesAndLabels), // Sabores vindos da API
            ...tempAdicionais // Adiciona os sabores temporários (adicionados pelo usuário)
        ];

        // Passo 4: Filtrar e mapear os dados para exibição
        const filteredData = combinedAdicionais
            .filter(i => i.label.toLowerCase().includes(value?.toLowerCase())); // Filtro baseado no valor

        // Retornar os dados filtrados para outros usos
        return filteredData;
    }

    async function callApiSabores(value) {
        // Passo 1: Obter os dados completos da API
        const data = await getAdicionais(userID, idEmpresa, vinculo_empresa, objIdEmpresa)
            .then((response) => response.data.adicionais) // Dados completos de 'adicionais'
            .then((response) => {
                // Aplica o filtro de acordo com o valor de type
                return response.filter((categoria) => categoria.type === "pizza");
            })

        // Passo 2: Salvar os dados completos em um estado para referência futura
        //console.log("DATA SABORES:::", data);
        setSabores(data); // Esse estado vai armazenar todos os dados dos adicionais sem filtros

        // Passo 3: Combinar sabores temporários com os dados da API
        const combinedSabores = [
            ...data.map(mapResponseToValuesAndLabels), // Sabores vindos da API
            ...tempSabores // Adiciona os sabores temporários (adicionados pelo usuário)
        ];

        // Passo 4: Filtrar e mapear os dados para exibição
        const filteredData = combinedSabores
            .filter(i => i.label.toLowerCase().includes(value?.toLowerCase())); // Filtro baseado no valor

        // Retornar os dados filtrados para outros usos
        return filteredData;
    }


    const [errImg, setErrImg] = React.useState(false);
    const [images, setImages] = React.useState([]);
    const maxNumber = 1;
    const maxMbFileSize = 2 * 1024 * 1024; // 2Mb

    /*const onChangeImg = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };*/
    // Assuming 'onChangeImg' is your handler for updating images

    const onChangeImg = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
        // Update the images state with the new list of images
        // If you need to immediately trigger updates to the backend when images change,
        // you can include that logic here, similar to what you might have in your form submission handler.
        // For example:
        const imagesToString = imageList.length > 0 ? imageList.map(image => image.data_url).join(", ") : "";
        // You can then use imagesToString to update the database or handle it during form submission[]
        setImagesToString(imagesToString);
    };

    /*const handleChangeValuePreco = (event) => {
        const inputValue = event.target.value.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        setValuePreco(inputValue);
        if (event.onChange) {
            event.onChange(parseFloat(inputValue.replace(/,/g, '')));
        }
    };*/

    // Carregar os dados de adicionais
    useEffect(() => {
        const loadAdicionais = async () => {
            const response = await getAdicionais(userID, idEmpresa, vinculo_empresa, objIdEmpresa);
            const adicionaisData = response.data.adicionais;
            setAdicionais(adicionaisData); // Dados de adicionais
        };
        loadAdicionais();
    }, []); // Esse effect roda apenas uma vez quando o componente é montado.

    // Carregar os dados de sabores
    useEffect(() => {
        const loadSabores = async () => {
            const response = await getAdicionais(userID, idEmpresa, vinculo_empresa, objIdEmpresa);
            const saboresData = response.data.adicionais.filter(categoria => categoria.type === "pizza");
            setSabores(saboresData); // Dados de sabores
        };
        loadSabores();
    }, []); // Esse effect também roda apenas uma vez quando o componente é montado.

    /*useEffect(() => {
        const loadItem = async () => {

            //console.log("itemObjId>",itemObjId);
            const response = await getItem(userID, idEmpresa, vinculo_empresa, objIdEmpresa, itemObjId)
            const itemData = response.data.item;
            setItem(itemData);
            if (itemData) {
                setselectData({
                    value: response.data.item.category_item_id,
                    label: response.data.item.category_item_title,
                })
                
                // Mapeia e seta os dados de adicionais
                if (itemData.adicionais.length > 0) {
                    const mappedAdicionais = itemData.adicionais
                        .map(adicional => {
                            const adicionalCompleto = adicionais.find((adicionalCompletos) => adicionalCompletos._id === adicional.value);
                            if (adicionalCompleto && (adicionalCompleto.type === "simples" || !adicionalCompleto.type)) {
                                return {
                                    value: adicional.value,
                                    label: adicional.label,
                                };
                            }
                            return null; // Exclui itens não válidos
                        })
                        .filter(Boolean); // Remove null/undefined
                    setselectDataAdicionais(mappedAdicionais);
                    setWithAdicional(true);
                }
                // Mapeia e seta os dados de sabores
                if (itemData.adicionais.length > 0) {
                    const mappedSabores = itemData.adicionais
                        .map(adicional => {
                            const adicionalCompleto = sabores.find((adicionalCompletos) => adicionalCompletos._id === adicional.value);
                            if (adicionalCompleto && adicionalCompleto.type === "pizza") {
                                return {
                                    value: adicional.value,
                                    label: adicional.label,
                                };
                            }
                            return null; // Exclui itens não válidos
                        })
                        .filter(Boolean); // Remove null/undefined
                    setSelectDataSabores(mappedSabores);
                }


                setOrderItem(response.data.item.order)
                setValueDescricao(response.data.item.description)
                setValuePreco(response.data.item.price)
                console.log("response.data.item.image>", response.data.item.image)
                // Verifica se a imagem é uma string (URL) e a transforma em um array
                console.log(typeof response.data.item.image)
                const imagesFromDb = response.data.item.image; // This should be your code to get images from DB
                // Assuming imagesFromDb is a string URL or an empty string
                const imagesArray = imagesFromDb === "" ? [] : [{ data_url: imagesFromDb }];
                setImages(imagesArray);
                setImagesToString(imagesFromDb);
                formik.setValues({
                    vinculo_categoria: response.data.item.category_item_id,
                    title: response.data.item.title,
                    preco: response.data.item.price,
                });
            }

        };

        loadItem();
    }, [adicionais, sabores, refresh]);*/
    useEffect(() => {
        const loadItem = async () => {
            const response = await getItem(userID, idEmpresa, vinculo_empresa, objIdEmpresa, itemObjId)
            const itemData = response.data.item;
            setItem(itemData);
            if (itemData) {
                setselectData({
                    value: itemData.category_item_id,
                    label: itemData.category_item_title,
                })

                // Definir sabores a partir do banco de dados + os temporários
                if (itemData.adicionais.length > 0) {
                    const mappedSabores = [
                        ...itemData.adicionais
                            .map(adicional => {
                                const adicionalCompleto = sabores.find(adicionalCompletos => adicionalCompletos._id === adicional.value);
                                if (adicionalCompleto && adicionalCompleto.type === "pizza") {
                                    return { value: adicional.value, label: adicional.label };
                                }
                                return null;
                            })
                            .filter(Boolean),
                        ...tempSabores // Adiciona os sabores temporários aqui
                    ];
                    setSelectDataSabores(mappedSabores);
                }

                // Processar outros dados (adicionais, imagens, etc.)
                if (itemData.adicionais.length > 0) {
                    const mappedAdicionais = [
                        ...itemData.adicionais
                            .map(adicional => {
                                const adicionalCompleto = adicionais.find(adicionalCompletos => adicionalCompletos._id === adicional.value);
                                if (adicionalCompleto && (adicionalCompleto.type === "simples" || !adicionalCompleto.type)) {
                                    return { value: adicional.value, label: adicional.label };
                                }
                                return null;
                            })
                            .filter(Boolean), // Remove null/undefined
                        ...tempAdicionais
                    ];
                    setselectDataAdicionais(mappedAdicionais);
                    setWithAdicional(true);
                }

                // Outros estados
                setOrderItem(response.data.item.order);
                setValueDescricao(response.data.item.description);
                setValuePreco(response.data.item.price);
                setImages(response.data.item.image ? [{ data_url: response.data.item.image }] : []);
                setImagesToString(response.data.item.image);
                //console.log("O que voltou do item>", response.data.item)
                formik.setValues({
                    vinculo_categoria: response.data.item.category_item_id,
                    title: response.data.item.title,
                    preco: response.data.item.price,
                    type: response.data.item.type,
                });
            }
        };

        loadItem();
    }, [adicionais, sabores, refresh, tempSabores, tempAdicionais]); // Adiciona ao array de dependências


    useEffect(() => {
        // Se selectDataAdicionais tiver valores, faça a chamada à API
        if (selectDataAdicionais && selectDataAdicionais.length > 0) {
            // Chama a API para atualizar os adicionais
            selectDataAdicionais.forEach(adicional => {
                callApiAdicionais(adicional?.value);
            });
        }
        if (selectDataSabores && selectDataSabores.length > 0) {
            //console.log("CHAMOU AQUI?", selectDataSabores)
            // Chama a API para atualizar os adicionais
            selectDataSabores.forEach(sabor => {
                callApiSabores(sabor?.value);
            });
        }
    }, [refresh]);

    //Validações
    const SignUpSchema = Yup.object().shape({
        //email: Yup.string().email("Insira um e-mail válido").required("Obrigatório"),
        //cnpj: Yup.string().required("Obrigatório"),
        title: Yup.string().required("Obrigatório"),
        vinculo_categoria: !selectData.value && Yup.string().required("Obrigatório"),
        type: Yup.string().required("Obrigatório"),
        //disponibilidade: Yup.string().required("Obrigatório"),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
            vinculo_categoria: "",
            title: "",
            preco: 0,
            type: "",
        },
        onSubmit: (values) => {
            //alert(JSON.stringify(values, null, 2));
            console.log(values);

            //const description = descriptionRef.current.value;
            const description = valueDescricao;
            const title = values.title;
            const price = values.preco;
            const type = values.type;
            //handleSaveImg();
            if (withAdicional && selectDataAdicionais.length === 0 && etapaAtiva === 2) {
                toast("Selecione os adicionais", { autoClose: 3000, type: "error" });
                return;
            }

            handleSubmitAPI(title, description, price, type);

        },
    });

    const navigate = useNavigate();

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/list-categoria");
    }

    const handleGerenciarGrupos = () => {
        navigate("/list-adicional");
    }

    const handleSubmitAPI = (title, description, price, type) => {
        //e.preventDefault();

        const createdBy = userID;
        const out = false;
        const category_item_id = selectData.value;
        const category_item_title = selectData.label;
        const id_item = item.id_item;
        const has_adicional = withAdicional;//adicionais?.length > 0 ? true : false;
        const adicionais = selectDataAdicionais.length > 0 || selectDataSabores.length > 0
            ? [...selectDataAdicionais, ...selectDataSabores]
            : [];
        // Assuming images is an array of objects, each with a 'data_url' key
        //const imagesToString = images.length > 0 ? images.map(image => image.data_url).join(", ") : "";
        console.log("DEU SUBMIT")

        //Registrando o item
        console.log("submit update item!", { createdBy, itemObjId, id_item, idEmpresa, orderItem, category_item_id, category_item_title, title, description, out, imagesToString, price, has_adicional, adicionais, type });
        updateItens(createdBy, itemObjId, id_item, idEmpresa, orderItem, category_item_id, category_item_title, title, description, out, imagesToString, price, has_adicional, adicionais, type).then(cast => {
            if (cast.status === 200) {
                toast(cast.data.msg, { autoClose: 5000, type: "success" });
                navigate("/list-categoria");
            }
            else {
                toast(cast.data.msg, { autoClose: 5000, type: "error" });
            }

        }).catch(err => {
            console.log('Oh noooo!!');
            const msg = err.response.data.msg;
            //console.log("MENSAGEM", msg)
            toast(msg, { autoClose: 5000, type: "error" });

        });

    }


    const [valueDescricao, setValueDescricao] = useState('');

    const handleMaxCharDescricao = (e) => {
        const newValue = e.target.value;
        if (newValue.length <= 1000) {
            setValueDescricao(newValue);
        }
    };

    //const [horariosToDelete, setHorariosToDelete] = useState("");
    const handleDeleteHorario = (horarioIndex) => {
        setDiasHorarios((prevDiasHorarios) => {
            const newDiasHorarios = [...prevDiasHorarios];
            newDiasHorarios.splice(horarioIndex, 1);
            return newDiasHorarios;
        });
    };

    const handleOptionChange = (event) => {
        if (event.target.value === "sempre" || event.target.value === "pausado") {
            setDiasHorarios([
                {
                    dias: new Set([""]),
                    startTime: "00:00",
                    endTime: "23:59",
                }
            ]);
        }
        setSelectedOption(event.target.value);
    };

    const horariosPorDia = diasHorarios.reduce((acc, { dias, startTime, endTime }) => {
        dias.forEach((dia) => {
            if (!acc[dia]) {
                acc[dia] = {
                    dia,
                    startTime,
                    endTime,
                };
            } else {
                const { startTime: startTimeAcc, endTime: endTimeAcc } = acc[dia];
                const [startTimeHour, startTimeMin] = startTime.split(':').map(Number);
                const [startTimeAccHour, startTimeAccMin] = startTimeAcc.split(':').map(Number);
                const [endTimeHour, endTimeMin] = endTime.split(':').map(Number);
                const [endTimeAccHour, endTimeAccMin] = endTimeAcc.split(':').map(Number);

                if (startTimeHour < startTimeAccHour || (startTimeHour === startTimeAccHour && startTimeMin < startTimeAccMin)) {
                    acc[dia].startTime = startTime;
                }

                if (endTimeAccHour < endTimeHour || (endTimeAccHour === endTimeHour && endTimeAccMin < endTimeMin)) {
                    acc[dia].endTime = endTime;
                }
            }
        });

        return acc;
    }, {});

    const onChange = (event, nameObject, isNumber) => {
        setValuePreco(isNumber ? Number(event.target.value) : event.target.value);
        formik.setFieldValue("preco", isNumber ? Number(event.target.value) : event.target.value);
    };

    const horariosUnicos = Object.values(horariosPorDia);

    const handleDayChange = (horarioIndex, key) => {
        const newDiasHorarios = [...diasHorarios];
        const horario = newDiasHorarios[horarioIndex];

        if (horario.dias.has(key)) {
            horario.dias.delete(key);
        } else {
            horario.dias.add(key);
        }

        setDiasHorarios(newDiasHorarios);
    };

    const validateTime = (value) => {
        const regex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
        return regex.test(value);
    };
    const handleTimeChange = (horarioIndex, setTime, event) => {
        const value = event.target.value;
        if (validateTime(value)) {
            const newHorarios = [...diasHorarios];
            newHorarios[horarioIndex][setTime] = value;
            setDiasHorarios(newHorarios);
        }
    };

    const handleAddHorario = () => {
        setDiasHorarios(diasHorarios.concat({
            dias: new Set(),
            startTime: "00:00",
            endTime: "23:59",
        }));
    };

    const isDayAlreadySelectedInHorario = (day, currentHorarioIndex) => {
        return diasHorarios
            .filter((horario, index) => index !== currentHorarioIndex)
            .some((horario) => horario.dias.has(day));
    };

    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };


    return (

        <>
            <PermissionGate permissions={['default']}>


                <LeftMenu setSidebar={setSidebar} sidebar={sidebar} />

                <ModalAddGroupAdicionais showModalAddGroupAdd={showModalAddGroupAdd}
                    setShowModalAddGroupAdd={setShowModalAddGroupAdd}
                    selectDataAdicionais={selectDataAdicionais} setselectDataAdicionais={setselectDataAdicionais}
                    refresh={refresh} setRefresh={setRefresh}
                    item={item}
                    setTempAdicionais={setTempAdicionais}
                />

                <ModalAddGroupSabores showModalSaboresGroupAdd={showModalSaboresGroupAdd}
                    setShowModalSaboresGroupAdd={setShowModalSaboresGroupAdd}
                    selectDataSabores={selectDataSabores} setSelectDataSabores={setSelectDataSabores}
                    refresh={refresh} setRefresh={setRefresh}
                    item={item}
                    setTempSabores={setTempSabores}
                />

                <Teste sidebar={sidebar}>
                    <div className="w-100 p-5">
                        {/*<form onSubmit={handleSubmitButton}>*/}
                        <form onSubmit={formik.handleSubmit} >

                            <div className="form-header" style={{ marginBottom: 0 }}>
                                <div className="title">
                                    {console.log("item::", item)}
                                    <h1>{modelo?.value === 2 ? "Editar Tamanho da Pizza" : "Editar Item"}</h1>
                                </div>
                            </div>

                            <div style={{ display: "flex", justifyContent: "right" }}>

                                <div className="div-buttons">
                                    <div className="continue-button">
                                        <button type="submit">
                                            <AiIcons.AiOutlineSave style={{ marginRight: "5px", fontSize: "22px", marginBottom: "2px" }} /><a>Salvar</a>
                                        </button>
                                    </div>

                                    <div className="back-button">
                                        <button onClick={handleBack}>
                                            <SlIcons.SlActionUndo style={{ color: "#ff4c4c", marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} /><a >Voltar</a>
                                        </button>
                                    </div>
                                </div>

                            </div>

                            <div className="contentItemComplete flex-column flex-lg-row">
                                <div className="input-group inputGroup-etapasItem w-100" style={{ maxWidth: 200, maxHeight: 50 }}>
                                    {etapaAtiva === 1 &&
                                        <div className={etapaAtiva === 1 ? "etapasAddItem etapaAtiva" : "etapasAddItem"}>
                                            <label //onClick={() => setEtapaAtiva(1)}
                                            >Item
                                            </label>
                                        </div>
                                    }
                                    {item.type === "pizza" && etapaAtiva === 3 &&
                                        <div className={etapaAtiva === 3 ? "etapasAddItem etapaAtiva" : "etapasAddItem"}>
                                            <label //onClick={() => setEtapaAtiva(3)}
                                            >Sabores
                                            </label>
                                        </div>
                                    }
                                    {etapaAtiva === 2 &&
                                        <div className={etapaAtiva === 2 ? "etapasAddItem etapaAtiva" : "etapasAddItem"}>
                                            <label //onClick={() => setEtapaAtiva(2)}
                                            >Adicionais
                                            </label>
                                        </div>
                                    }

                                    {/*<div className={etapaAtiva===3?"etapasAddItem etapaAtiva":"etapasAddItem"} style={{borderBottom:'1px solid lightgray'}}>
                                    <label onClick={()=> setEtapaAtiva(1)}>3. Classificações</label>
                                </div>
                                <div className={etapaAtiva===4?"etapasAddItem etapaAtiva":"etapasAddItem"}>
                                    <label onClick={()=> setEtapaAtiva(1)}>4. Disponibilidade</label>
                                </div> */}
                                </div>

                                {etapaAtiva === 1 && (
                                    <>
                                        <div className="input-group inputGroup-adicinaItem mw-100 mw-100 mh-100 flex-column flex-lg-row ">
                                            <div className="formGroupRow flex-column align-items-center">
                                                <div className="divContainerAddItem" style={{ width: "100%" }}>
                                                    <div className="input-box" style={{ width: "100%", transition: "200ms" }}>
                                                        <label>Tipo<span style={{ color: "red" }}>*</span></label>
                                                        <div className="selectdiv disp">
                                                            <label style={{ width: "100%" }}>
                                                                <select
                                                                    id="type"
                                                                    name="type"
                                                                    value={formik.values.type} // Acessando o valor do campo no formik
                                                                    onChange={formik.handleChange} // Usando a função de mudança do formik
                                                                >
                                                                    <option value="">Selecione o tipo</option>
                                                                    {["simples", "pizza"].map((option, index) => (
                                                                        <option key={index} value={option}>
                                                                            {option.charAt(0).toUpperCase() + option.slice(1)} {/* Capitalizando a primeira letra */}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </label>
                                                        </div>
                                                        {formik.errors.type && (
                                                            <div className="error">{formik.errors.type}</div>
                                                        )}
                                                    </div>

                                                    <div className="input-box" style={{ width: "100%", transition: "200ms" }}>
                                                        <label>Categoria<span style={{ color: "red" }}>*</span></label>
                                                        {/*<!-- Custom select structure --> */}
                                                        <div className="selectdiv-teste" style={{ width: "100%", maxWidth: "100%" }}>
                                                            <label style={{ width: "100%" }}>
                                                                <AsyncSelect
                                                                    cacheOptions
                                                                    loadOptions={callApi}
                                                                    onChange={(data) => {
                                                                        setselectData(data);
                                                                    }}
                                                                    value={selectData}
                                                                    defaultOptions
                                                                    id="vinculo_categoria"
                                                                    type="vinculo_categoria"
                                                                    name="vinculo_categoria"
                                                                    className="asyncSelectInput"
                                                                    styles={{
                                                                        control: (baseStyles, state) => ({
                                                                            ...baseStyles,
                                                                            margin: "0.6rem 0",
                                                                            paddingLeft: "2.5rem",
                                                                            height: "45px",
                                                                            border: "none",
                                                                            outline: state.isFocused ? '1px solid #4070f4;' : '0',
                                                                            borderRadius: "10px",
                                                                            boxShadow: "1px 1px 6px #0000001c",
                                                                            fontSize: "0.8rem",
                                                                        }),
                                                                        input: (style) => ({
                                                                            ...style,
                                                                            color: "black",
                                                                        }),
                                                                        singleValue: (baseStyles) => ({
                                                                            ...baseStyles,
                                                                            color: selectData.value ? "black" : "gray",
                                                                        }),
                                                                        valueContainer: (baseStyles) => ({
                                                                            ...baseStyles,
                                                                            fontWeight: "lighter",
                                                                        }),
                                                                        option: (styles) => {
                                                                            return {
                                                                                ...styles,
                                                                                fontWeight: "lighter",
                                                                                color: "black",
                                                                            };
                                                                        },
                                                                    }}
                                                                />
                                                                {formik.errors.vinculo_categoria && (
                                                                    <div className="error" style={{ fontWeight: "lighter" }}>{formik.errors.vinculo_categoria}</div>
                                                                )}
                                                            </label>
                                                        </div>
                                                        {/*<!-- Custom select structure --> */}
                                                    </div>


                                                    <div className="input-box" style={{ width: "100%", transition: sidebar ? "200ms" : "100ms" }}>
                                                        <label>{modelo?.value === 2 ? "Nome do tamanho" : "Nome do item"}<span style={{ color: "red" }}>*</span></label>
                                                        <input
                                                            placeholder={modelo?.value === 2 ? "Ex.: Grande" : "Digite o nome do item"}
                                                            id="title"
                                                            type="title"
                                                            name="title"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.title}
                                                        />
                                                        {formik.errors.title && (
                                                            <div className="error">{formik.errors.title}</div>
                                                        )}
                                                    </div>

                                                    <div className="input-box descricaoItem" style={{ width: "100%", transition: sidebar ? "200ms" : "100ms" }}>
                                                        <label>Descrição</label>
                                                        {/*<input 
                                                placeholder="Digite a descrição" 
                                                id="description"
                                                type="description"
                                                name="description"
                                                onChange={formik.handleChange}
                                                value={formik.values.description}
                                                />
                                                */}
                                                        <textarea placeholder="Digite aqui a descrição desejada"
                                                            value={valueDescricao} onChange={handleMaxCharDescricao} maxLength="1000" className="text-wrap-description" ref={descriptionRef} />
                                                        <div className="divCounterChar">{valueDescricao.length}/1000</div>
                                                        {formik.errors.description && (
                                                            <div className="error">{formik.errors.description}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="divImgAddItem">
                                                    <ImageUploading
                                                        multiple
                                                        value={images}
                                                        onChange={onChangeImg}
                                                        maxNumber={maxNumber}
                                                        maxFileSize={maxMbFileSize}
                                                        acceptType={["jpg", "png"]}
                                                        dataURLKey="data_url"
                                                    >

                                                        {({
                                                            imageList,
                                                            onImageUpload,
                                                            onImageRemoveAll,
                                                            onImageUpdate,
                                                            onImageRemove,
                                                            isDragging,
                                                            dragProps,
                                                            errors
                                                        }) => (
                                                            // write your building UI

                                                            <div className="upload__image-wrapper imgItem"
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    width: "100%",
                                                                    //marginLeft:"5%",
                                                                    height: "100%",
                                                                    padding: "10px"
                                                                }}
                                                            >

                                                                <div onClick={images.length === 0 ? onImageUpload : null} {...dragProps}
                                                                    style={{
                                                                        border: imageList.length > 0 ? "solid 1px lightgray" : "dashed 1px #4281FF",
                                                                        borderRadius: "5px", //width:"198px", height:"198px",
                                                                        background: "rgb(247,247,247)",
                                                                        cursor: images.length === 0 ? 'pointer' : '',
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        flexDirection: "column"
                                                                    }}
                                                                    className="divUploadImgItem"
                                                                >
                                                                    {Array.isArray(imageList) && imageList.length === 0 && (
                                                                        <>
                                                                            <div className="iconLanche">
                                                                                <MdFastfood style={{ fontSize: 44 }} />
                                                                            </div>
                                                                            <div style={{ fontSize: 11, fontWeight: '700' }}>Escolha a foto</div>
                                                                        </>
                                                                    )}
                                                                    {isDragging ? "Solte aqui..." : imageList.length > 0 ? "" : <a style={{ padding: '0 33px', fontSize: 11 }}>Clique aqui, ou arraste a foto para cá.</a>}
                                                                    {/*Array.isArray(imageList) && */imageList.map((image, index) => (
                                                                        <div key={index} className="image-item" style={{ justifyContent: "center", display: "grid", gap: 3 }}>
                                                                            {/*<div className="boxImgToFit">    
                                                                    <img src={image['data_url']} alt="" width="138"/>
                                                                </div> */}
                                                                            <CImage rounded thumbnail fluid src={image['data_url']} /*width={150} height={150}*/ />
                                                                            <div className="image-item__btn-wrapper addItemPart" style={{ marginLeft: "0px" }}>
                                                                                <button className="btnAttImgItem" type="button" style={{ fontSize: "14px" }}
                                                                                    onClick={(event) => {
                                                                                        event.stopPropagation();
                                                                                        onImageUpdate(index)
                                                                                    }
                                                                                    }>
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="18px"
                                                                                        height="18px"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        stroke="#5A5A5A"
                                                                                        strokeWidth={2}
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                        className="feather feather-refresh-ccw"
                                                                                    >
                                                                                        <polyline points="1 4 1 10 7 10" />
                                                                                        <polyline points="23 20 23 14 17 14" />
                                                                                        <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15" />
                                                                                    </svg>
                                                                                </button>
                                                                                <button className="btnDelImgItem" type="button" style={{ fontSize: "14px" }} onClick={(event) => {
                                                                                    event.stopPropagation();
                                                                                    onImageRemove(index)
                                                                                }
                                                                                }>
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="18px"
                                                                                        height="18px"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        stroke="#5A5A5A"
                                                                                        strokeWidth={2}
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                        className="feather feather-trash-2"
                                                                                    >
                                                                                        <polyline points="3 6 5 6 21 6" />
                                                                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                                                                        <line x1={10} y1={11} x2={10} y2={17} />
                                                                                        <line x1={14} y1={11} x2={14} y2={17} />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>


                                                                {errors && (
                                                                    <div>
                                                                        {errors.maxNumber && (
                                                                            <span style={{ color: "red" }}>Limite de {maxNumber} imagem!</span>
                                                                        )}
                                                                        {errors.acceptType && (
                                                                            <span style={{ color: "red" }}>Formato de arquivo não suportado!</span>
                                                                        )}
                                                                        {errors.maxFileSize && (
                                                                            <span style={{ color: "red", fontSize: 12 }}>Arquivo Excede o Tamanho Máximo Permitido!</span>
                                                                        )}
                                                                        {/* {errors.resolution && (
                                                            <span>Resolução muito baixa.</span>
                                                        )} */}
                                                                    </div>
                                                                )}

                                                                {errImg && (
                                                                    <div>
                                                                        <span style={{ color: "red" }}>Imagem Obrigatória!</span>
                                                                    </div>
                                                                )
                                                                }
                                                                <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "0px" }}>
                                                                    <div><a style={{ fontSize: "11px", color: 'gray' }}>(Serão aceitos somente arquivos JPG e PNG de no Máximo 2mb)</a></div>
                                                                </div>

                                                            </div>



                                                        )}

                                                    </ImageUploading>

                                                </div>
                                            </div>

                                            {
                                                modelo && modelo.value === 2 ?
                                                    null
                                                    :
                                                    <div>
                                                        <div className="input-box precoItemCustom" style={{ width: "100%", transition: sidebar ? "200ms" : "100ms" }}>
                                                            <label>Preço</label>
                                                            <InputMoney
                                                                onChange={(event) => onChange(event, 'price', true)}
                                                                value={valuePreco}
                                                                onFocus={handleFocus}
                                                                onBlur={handleBlur}
                                                                margin="0px 0px 16px 0px"
                                                                style={{
                                                                    outline: isFocused ? '2px solid #4070f4' : 'none',
                                                                    borderRadius: 10,
                                                                    margin: '0.6rem 0 0.2rem 0',
                                                                }}
                                                                className=""
                                                                placeholder="Preço"
                                                            />
                                                            {formik.errors.preco && (
                                                                <div className="error">{formik.errors.preco}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                    </>
                                )}

                                {etapaAtiva === 2 && (
                                    <div className="input-group inputGroup-adicinaItem mw-100 mh-100">
                                        <div className="formGroupRow flex-column flex-lg-row gap-4">
                                            <div className="divContainerAddItem" style={{ width: "100%" }}>
                                                <div className="gerenciarAdicionaisBtn" onClick={handleGerenciarGrupos}>
                                                    <span>Gerenciar grupos</span>
                                                </div>
                                                <div className="radio-button-groupAdicionais" style={{ transition: sidebar ? "200ms" : "100ms", margin: 20 }}>
                                                    <div className="radio-optionAdicionais">
                                                        <input
                                                            id="opcional"
                                                            type="radio"
                                                            name="withAdicional"
                                                            value="opcional"
                                                            checked={!withAdicional}
                                                            onChange={() => {
                                                                setWithAdicional(false)
                                                                setselectDataAdicionais([]);
                                                            }
                                                            }
                                                        />
                                                        <div className="labelOptAdicionais">
                                                            <label htmlFor="opcional">Sem Adicional</label>
                                                        </div>
                                                    </div>
                                                    <div className="radio-optionAdicionais">
                                                        <input
                                                            id="obrigatorio"
                                                            type="radio"
                                                            name="withAdicional"
                                                            value="obrigatorio"
                                                            checked={withAdicional}
                                                            onChange={() => setWithAdicional(true)}
                                                        />
                                                        <div className="labelOptAdicionais">
                                                            <label htmlFor="obrigatorio">Com Adicional</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {withAdicional && (
                                                    <div className="input-box" style={{ width: "100%", transition: "200ms" }}>
                                                        <label>Adicionais<span style={{ color: "red" }}>*</span></label>
                                                        {/*<!-- Custom select structure --> */}
                                                        <div className="selectdiv-teste" style={{ width: "100%", maxWidth: "100%" }}>
                                                            {console.log("selectDataAdicionais::", selectDataAdicionais)}
                                                            <label style={{ width: "100%" }}>
                                                                <AsyncSelect
                                                                    isMulti
                                                                    height="auto"
                                                                    cacheOptions
                                                                    loadOptions={callApiAdicionais}
                                                                    onChange={(data) => {
                                                                        setselectDataAdicionais(data);
                                                                        // Verifique se algum item de tempSabores / tempAdicionais não está mais presente em data
                                                                        const tempAdicionaisToRemove = tempAdicionais.filter(item =>
                                                                            !data.some(selectedItem => selectedItem.value === item.value)
                                                                        );

                                                                        if (tempAdicionaisToRemove.length > 0) {
                                                                            // Se algum item não estiver em 'data', remova-o
                                                                            setTempAdicionais(prevState =>
                                                                                prevState.filter(item => !tempAdicionaisToRemove.some(itemToRemove => itemToRemove.value === item.value))
                                                                            );
                                                                        }
                                                                    }}
                                                                    value={selectDataAdicionais}
                                                                    defaultOptions
                                                                    placeholder={"Selecione os adicionais"}
                                                                    id="vinculo_adicional"
                                                                    type="vinculo_adicional"
                                                                    name="vinculo_adicional"
                                                                    className="asyncSelectInputAdicionais"
                                                                    styles={{
                                                                        control: (baseStyles, state) => ({
                                                                            ...baseStyles,
                                                                            margin: "0.6rem 0",
                                                                            paddingLeft: "2.5rem",
                                                                            height: "auto",
                                                                            border: "none",
                                                                            outline: state.isFocused ? '1px solid #4070f4;' : '0',
                                                                            borderRadius: "10px",
                                                                            boxShadow: "1px 1px 6px #0000001c",
                                                                            fontSize: "0.8rem",
                                                                        }),
                                                                        input: (style) => ({
                                                                            ...style,
                                                                            color: "black",
                                                                        }),
                                                                        singleValue: (baseStyles) => ({
                                                                            ...baseStyles,
                                                                            color: selectData.value ? "black" : "gray",
                                                                        }),
                                                                        valueContainer: (baseStyles) => ({
                                                                            ...baseStyles,
                                                                            fontWeight: "lighter",
                                                                        }),
                                                                        option: (styles) => {
                                                                            return {
                                                                                ...styles,
                                                                                fontWeight: "lighter",
                                                                                color: "black",
                                                                            };
                                                                        },
                                                                    }}
                                                                />
                                                                {formik.errors.vinculo_adicional && (
                                                                    <div className="error" style={{ fontWeight: "lighter" }}>{formik.errors.vinculo_adicional}</div>
                                                                )}
                                                            </label>
                                                        </div>
                                                        {/*<!-- Custom select structure --> */}
                                                    </div>
                                                )
                                                }

                                                <button type="button" className="btnCreateGroupAdicionais"
                                                    onClick={() => setShowModalAddGroupAdd(!showModalAddGroupAdd)}><IoIosAddCircleOutline fontSize={18}
                                                    />
                                                    Criar Grupo de Adicionais
                                                </button>


                                            </div>

                                            {/* 
                                            <div className="divBtnsBackFoward">
                                                <div className="btnsBackFoward">
                                                    <button type="button" className="btnAvancarTelasEditItem"
                                                    //onClick={() => setEtapaAtiva(1)} 
                                                    >
                                                        <span>Item</span><IoArrowUndo />
                                                    </button>
                                                    {item.type === "pizza" &&
                                                        <button type="button" className="btnAvancarTelasEditItem"
                                                        //onClick={() => setEtapaAtiva(3)} 
                                                        >
                                                            <span>Sabores</span><IoArrowUndo />
                                                        </button>
                                                    }
                                                </div>
                                            </div>*/}
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            {adicionais && adicionais
                                                .filter((adicional) =>
                                                    selectDataAdicionais?.some(selected => selected?.value === adicional._id) // Verifica se algum valor de selectDataAdicionais corresponde ao _id do adicional
                                                )
                                                .map((categoriaAdicional, i) => (
                                                    <div key={categoriaAdicional._id} index={i}>
                                                        {/* Renderiza a categoria de adicionais aqui */}
                                                        <div
                                                            style={{
                                                                backgroundColor: i % 2 === 0 ? 'white' : 'white',
                                                            }}
                                                            className={`${'resultCategoriaAdicionais'} ${i % 2 === 0 ? 'even' : 'odd'}`}
                                                        >
                                                            <div style={{ display: 'flex', flexDirection: 'row', gap: 80, alignItems: 'center', width: '90%', justifyContent: 'space-between', paddingLeft: 10 }}>
                                                                <div style={{ maxWidth: '50vw' }}>
                                                                    <div className="flex-column flex-md-row" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                                                        <div className="nomeCategoria">{categoriaAdicional.title}</div>
                                                                        <div className={categoriaAdicional.mandatory ? "divMandatory" : "divOpcional"}>
                                                                            <span>{categoriaAdicional.mandatory ? "Obrigatório" : "Opcional"}</span>
                                                                        </div>
                                                                        <div>
                                                                            <span>Adicionais selecionáveis de {categoriaAdicional.min} até {categoriaAdicional.max}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="divDescricaoAdicionais">{categoriaAdicional.description}</div>
                                                                </div>
                                                            </div>
                                                            {/* 
                                                            <div>
                                                                <a className="btn btn-sm btn-primary editaCategoria">
                                                                    <FiIcons.FiEdit2 style={{ color: 'white' }} />
                                                                </a>
                                                                <a className="btn btn-sm btn-danger deletaCategoria" style={{ marginLeft: '5px' }}>
                                                                    <FiIcons.FiTrash2 style={{ color: 'white' }} />
                                                                </a>
                                                            </div>
                                                            */}
                                                        </div>

                                                        <div className="resultAdicionaisChildren">
                                                            {categoriaAdicional.adicionais && categoriaAdicional.adicionais.length > 0 && categoriaAdicional.adicionais.map((adicional, j) => (
                                                                <div key={adicional._id} index={j}>
                                                                    <div className="divItemAdicionalInfo">
                                                                        <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                                                                            {adicional.image === "" || !adicional.image ? (
                                                                                <div>
                                                                                    <MdFastfood style={{ fontSize: 50 }} />
                                                                                </div>
                                                                            ) : (
                                                                                <img width={50} height={50} src={adicional.image} alt={adicional.title} />
                                                                            )}
                                                                            {adicional.title}
                                                                        </div>
                                                                        <div className="divEditRmvSubAdicionais">
                                                                            {adicional.price > 0 ? (
                                                                                <div>A partir de R$ {adicional.price.toFixed(2).toString().replace('.', ',')}</div>
                                                                            ) : (
                                                                                <div>Adicional Grátis</div>
                                                                            )}
                                                                            {/*<TbDotsVertical />*/}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {/* 
                                                            <div className="divCadastroAdicionalChildren">
                                                                <span>Cadastrar Adicional</span>
                                                            </div>
                                                            */}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>


                                    </div>
                                )}

                                {etapaAtiva === 3 && item.type === "pizza" && (
                                    <div className="input-group inputGroup-adicinaItem mw-100 mh-100">
                                        <div className="formGroupRow flex-column flex-lg-row gap-4">
                                            <div className="divContainerAddItem" style={{ width: "100%" }}>

                                                <div className="gerenciarAdicionaisBtn" onClick={handleGerenciarGrupos}>
                                                    <span>Gerenciar grupos</span>
                                                </div>
                                                <div className="radio-button-groupAdicionais" style={{ transition: sidebar ? "200ms" : "100ms", margin: 20 }}>
                                                    <h5>Grupo de Sabores</h5>
                                                </div>

                                                <div className="input-box" style={{ width: "100%", transition: "200ms" }}>
                                                    <label>Grupos Selecionados<span style={{ color: "red" }}>*</span></label>
                                                    {/*<!-- Custom select structure --> */}
                                                    <div className="selectdiv-teste" style={{ width: "100%", maxWidth: "100%" }}>
                                                        {console.log("selectDataSabores::", selectDataSabores)}
                                                        <label style={{ width: "100%" }}>
                                                            <AsyncSelect
                                                                isMulti
                                                                height="auto"
                                                                cacheOptions
                                                                loadOptions={callApiSabores}
                                                                onChange={(data) => {
                                                                    // Primeiro, vamos garantir que apenas novos itens sejam adicionados
                                                                    setSelectDataSabores(data);
                                                                    // Verifique se algum item não está mais presente em data
                                                                    const tempSaboresToRemove = tempSabores.filter(item =>
                                                                        !data.some(selectedItem => selectedItem.value === item.value)
                                                                    );

                                                                    if (tempSaboresToRemove.length > 0) {
                                                                        // Se algum item não estiver em 'data', remova-o
                                                                        setTempSabores(prevState =>
                                                                            prevState.filter(item => !tempSaboresToRemove.some(itemToRemove => itemToRemove.value === item.value))
                                                                        );
                                                                    }
                                                                }}
                                                                value={selectDataSabores}
                                                                defaultOptions
                                                                placeholder={"Selecione os grupos de sabores que deseja incluir no item"}
                                                                id="vinculo_sabor"
                                                                type="vinculo_sabor"
                                                                name="vinculo_sabor"
                                                                className="asyncSelectInputAdicionais"
                                                                styles={{
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        margin: "0.6rem 0",
                                                                        paddingLeft: "2.5rem",
                                                                        height: "auto",
                                                                        border: "none",
                                                                        outline: state.isFocused ? '1px solid #4070f4;' : '0',
                                                                        borderRadius: "10px",
                                                                        boxShadow: "1px 1px 6px #0000001c",
                                                                        fontSize: "0.8rem",
                                                                    }),
                                                                    input: (style) => ({
                                                                        ...style,
                                                                        color: "black",
                                                                    }),
                                                                    singleValue: (baseStyles) => ({
                                                                        ...baseStyles,
                                                                        color: selectData.value ? "black" : "gray",
                                                                    }),
                                                                    valueContainer: (baseStyles) => ({
                                                                        ...baseStyles,
                                                                        fontWeight: "lighter",
                                                                    }),
                                                                    option: (styles) => {
                                                                        return {
                                                                            ...styles,
                                                                            fontWeight: "lighter",
                                                                            color: "black",
                                                                        };
                                                                    },
                                                                }}
                                                            />
                                                            {formik.errors.vinculo_sabor && (
                                                                <div className="error" style={{ fontWeight: "lighter" }}>{formik.errors.vinculo_sabor}</div>
                                                            )}
                                                        </label>
                                                    </div>
                                                    {/*<!-- Custom select structure --> */}
                                                </div>

                                                <button type="button" className="btnCreateGroupAdicionais"
                                                    onClick={() => setShowModalSaboresGroupAdd(!showModalSaboresGroupAdd)}><IoIosAddCircleOutline fontSize={18}
                                                    />
                                                    Criar Grupo de Sabores
                                                </button>

                                            </div>


                                        </div>
                                        <div style={{ width: '100%' }}>
                                            {sabores && sabores
                                                .filter((adicional) =>
                                                    selectDataSabores?.some(selected => selected?.value === adicional._id) // Verifica se algum valor de selectDataAdicionais corresponde ao _id do adicional
                                                )
                                                .map((categoriaAdicional, i) => (
                                                    <div key={categoriaAdicional._id} index={i}>
                                                        {/* Renderiza a categoria de adicionais aqui */}
                                                        <div
                                                            style={{
                                                                backgroundColor: i % 2 === 0 ? 'white' : 'white',
                                                            }}
                                                            className={`${'resultCategoriaAdicionais'} ${i % 2 === 0 ? 'even' : 'odd'}`}
                                                        >
                                                            <div style={{ display: 'flex', flexDirection: 'row', gap: 80, alignItems: 'center', width: '90%', justifyContent: 'space-between', paddingLeft: 10 }}>
                                                                <div style={{ maxWidth: '50vw' }}>
                                                                    <div className="flex-column flex-md-row" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                                                        <div className="nomeCategoria">{categoriaAdicional.title}</div>
                                                                        <div className={categoriaAdicional.mandatory ? "divMandatory" : "divOpcional"}>
                                                                            <span>{categoriaAdicional.mandatory ? "Obrigatório" : "Opcional"}</span>
                                                                        </div>
                                                                        <div>
                                                                            <span>Sabores selecionáveis de {categoriaAdicional.min} até {categoriaAdicional.max}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="divDescricaoAdicionais">{categoriaAdicional.description}</div>
                                                                </div>
                                                            </div>
                                                            {/* 
                                                            <div>
                                                                <a className="btn btn-sm btn-primary editaCategoria">
                                                                    <FiIcons.FiEdit2 style={{ color: 'white' }} />
                                                                </a>
                                                                <a className="btn btn-sm btn-danger deletaCategoria" style={{ marginLeft: '5px' }}>
                                                                    <FiIcons.FiTrash2 style={{ color: 'white' }} />
                                                                </a>
                                                            </div>
                                                            */}
                                                        </div>

                                                        <div className="resultAdicionaisChildren">
                                                            {categoriaAdicional.adicionais && categoriaAdicional.adicionais.length > 0 && categoriaAdicional.adicionais.map((adicional, j) => (
                                                                <div key={adicional._id} index={j}>
                                                                    <div className="divItemAdicionalInfo">
                                                                        <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                                                                            {adicional.image === "" || !adicional.image ? (
                                                                                <div>
                                                                                    <MdFastfood style={{ fontSize: 50 }} />
                                                                                </div>
                                                                            ) : (
                                                                                <img width={50} height={50} src={adicional.image} alt={adicional.title} />
                                                                            )}
                                                                            {adicional.title}
                                                                        </div>
                                                                        <div className="divEditRmvSubAdicionais">
                                                                            {adicional.price > 0 ? (
                                                                                <div>A partir de R$ {adicional.price.toFixed(2).toString().replace('.', ',')}</div>
                                                                            ) : (
                                                                                <div>Grátis</div>
                                                                            )}
                                                                            {/*<TbDotsVertical />*/}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {/* 
                                                            <div className="divCadastroAdicionalChildren">
                                                                <span>Cadastrar Sabor</span>
                                                            </div>
                                                            */}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>


                                    </div>
                                )}


                            </div>

                        </form>
                    </div>
                </Teste>

            </PermissionGate>
        </>


    );
};

export default EditItem;