import React, { useEffect, useState } from 'react';
import Input from './Input';

const DECIMAL_SIZE = 2;

const InputMoney = ({ value, onChange, addonBefore = 'R$', ...props }) => {
  // Fallback para 0 caso o value seja undefined ou null
  const [currentValue, setCurrentValue] = useState(() => {
    const initialValue = value ?? 0;  // Se value for undefined ou null, usa 0
    return initialValue.toFixed(DECIMAL_SIZE).replace('.', ',');
  });

  useEffect(() => {
    if (value !== undefined && value !== null) {
      const valueString = value.toString();

      // Verifica se o valor não contém caracteres não numéricos
      if (!/\D/.test(valueString.replace('.', ''))) {
        setCurrentValue(value.toFixed(DECIMAL_SIZE).replace('.', ','));
      }
    }
  }, [value]);

  const handleOnChange = (event) => {
    const valueRemoved = event.target.value.replace(',', '');
    const sizeSlice = valueRemoved.length - DECIMAL_SIZE;

    // Garante que a formatação correta seja aplicada, separando os decimais
    const newValue = [valueRemoved.slice(0, sizeSlice), '.', valueRemoved.slice(sizeSlice)].join('');

    onChange({
      ...event,
      target: {
        ...event.target,
        value: newValue,
      },
    });
  };

  return (
    <Input addonBefore={addonBefore} value={currentValue} onChange={handleOnChange} {...props} />
  );
};

export default InputMoney;
