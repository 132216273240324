import React, { useState, useContext } from "react";
import AsyncSelect from 'react-select/async';
import './style.css'
import styled from 'styled-components';
import { SidebarContext } from "../../AppRoutes";
import { AuthContext } from "../../contexts/auth";

import { toast } from "react-toastify";

import { register, getEmpresas } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;



const User = () =>{
    const { user } = useContext(AuthContext);

    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES


    const INITIAL_DATA = {
        value: "",
        label: 'Selecione a empresa',
    };
    
    const [selectData, setselectData] = useState(INITIAL_DATA);
    const mapResponseToValuesAndLabels = (data) => ({
    value: data.id_empresa,
    label: data.id_empresa+" - "+data.cnpj+" - "+data.name,
    });
    
      
    async function callApi(value) {
    
        const data = await getEmpresas(user._id)
        .then((response) => response.data.empresa)//response.json())
        .then((response) => response.map(mapResponseToValuesAndLabels))
        .then((response) =>
            response.filter((i) => i.label.toLowerCase().includes(value.toLowerCase()))
        );

        return data;
    }
    

    //Validações
    const SignUpSchema = Yup.object().shape({
        email: Yup.string().email("Insira um e-mail válido").required("Obrigatório"),
        password: Yup.string().required("Obrigatório"),
        confirmPassword: Yup.string().required("Obrigatório"),
        tipo: Yup.string().required("Obrigatório"),
        vinculo: !selectData.value && Yup.string().required("Obrigatório"),
        nome: Yup.string().required("Obrigatório"),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        //enableReinitialize: true,
        initialValues: {
          email: "",
          password: "",
          confirmPassword: "",
          tipo: "",
          vinculo: "",
          nome: "",
        },
        onSubmit: (values) => {
          //alert(JSON.stringify(values, null, 2));
          //console.log(values);
          //setEmail(values.email);
          const email=values.email;
          //setPassword(values.password);
          const password=values.password;
          //setConfirmPassword(values.confirmPassword);
          const confirmPassword=values.confirmPassword;
          //setNome(values.nome);
          const nome=values.nome;
          //const vinculo=values.vinculo;
          let tipo = "";

          switch (values.tipo) {
            case 'Administrador': 
                tipo = "Admin";
                break;
            case 'Empresa': 
                tipo = "empresa";
                break;
            case 'Revenda': 
                tipo = "revenda";
                break;
            case 'Garçom': 
                tipo = "garcom";
                break;
            default:
                tipo = "empresa";
          }

          //console.log("values.tipo:::",values.tipo)
          if(values.tipo==="Administrador"){
            tipo = "Admin";
            //console.log("Passou 1");
          }
          if(values.tipo==="Empresa"){
            tipo = "empresa";
            //console.log("Passou 2");
          }
          if(values.tipo==="Revenda"){
            tipo = "revenda";
            //console.log("Passou 3");
          }
          
          console.log(selectData);
          setselectData(INITIAL_DATA);

          handleSubmitAPI(email,password,confirmPassword,nome,tipo);
          
        },
    });

    const navigate = useNavigate();
    
    const handleSubmitAPI = (email,password,confirmpassword,nome,tipoRole) =>{
        //e.preventDefault();
        console.log("selectData.value:",selectData.value);
        const vinculo = selectData.value;
        //console.log("VINCULO>>",vinculo);
        //const role = tipoRole;
        //console.log("Role Enviado:",tipoRole);
        const createdBy = user._id;
        //console.log("submit user!",{createdBy, nome, email, password, confirmpassword, vinculo, tipoRole});
        register(createdBy, nome, email, password, confirmpassword, vinculo, tipoRole).then(cast => {
            //console.log("Usuário criado com Sucesso!:::",cast)
            if (cast.status===201){
                toast(cast.data.msg, {autoClose: 5000, type: "success"})       
                navigate("/list-users");
            }else{
                toast(cast.data.msg, {autoClose: 5000, type: "error"})
            }
        }).catch(err => {
            //console.log('Ocorreu algum erro no servidor!');
            //console.log(err.response.data.msg);
            toast(err.response.data.msg, {autoClose: 5000, type: "error"})
        });
        
        
    }

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/list-users");
        
    }

    return(

        <>
        <PermissionGate permissions={['viewUser']}>
        

            <LeftMenu setSidebar={setSidebar} sidebar={sidebar}/>

            <Teste sidebar={sidebar}>
                <div className="w-100 p-4">
                    {/* <div className="form"> */}
                        {/*<form onSubmit={handleSubmitButton}>*/}
                        <form onSubmit={formik.handleSubmit} >
                        
                            <div className="form-header" style={{marginBottom:"0px"}}>
                                <div className="title">
                                    <h1>Cadastrar Usuário</h1>
                                </div>
                            </div>

                            <div style={{display:"flex", justifyContent:"right"/*, height:"80px"*/}}>


                                <div className="div-buttons flex-column flex-md-row">
                                    <div className="continue-button">                           
                                        <button type="submit">
                                            <AiIcons.AiOutlineSave style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Salvar</a> 
                                        </button>                            
                                    </div>

                                    <div className="back-button" style={{ textAlign: "start" }}>                                                       
                                        <button onClick={handleBack}>
                                            <SlIcons.SlActionUndo style={{color:"#ff4c4c",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Voltar</a> 
                                        </button>
                                    </div>
                                </div>

                            </div>
                        
                            <div className="input-group mh-100">

                                <div className="input-box w-100">
                                    <label>Papel do Usuário<span style={{color:"red"}}>*</span></label>
                                    {/*<!-- Custom select structure --> */}
                                    <div className="selectdiv">
                                    <label style={{width:"100%"}}>
                                        <select 
                                            id="tipo"
                                            type="tipo"
                                            name="tipo"
                                            onChange={formik.handleChange}
                                            value={formik.values.tipo}
                                            className={formik.values.tipo === ""?'invalid-option':'beleza'}
                                            style={{width:"100%"}}
                                        >
                                            <option value="" className="invalid-option">Selecione um Tipo</option>
                                            <PermissionGate permissions={['viewRoleAdmin']}><option>Administrador</option></PermissionGate>
                                            <PermissionGate permissions={['viewRoleAdmin']}><option>Garçom</option></PermissionGate>
                                            <PermissionGate permissions={['viewRoleEmpresa']}><option>Empresa</option></PermissionGate>
                                            <PermissionGate permissions={['viewRoleRevenda']}><option>Revenda</option></PermissionGate>
                                        </select>
                                        {formik.errors.tipo && (
                                        <div className="error" style={{fontWeight: "lighter"}}>{formik.errors.tipo}</div>
                                        )}
                                    </label>
                                    </div>
                                    {/*<!-- Custom select structure --> */}
                                </div>  

                                <div className="input-box w-100" >
                                    <label>Nome<span style={{color:"red"}}>*</span></label>
                                    <input 
                                    placeholder="Digite o nome do Usuário"
                                    id="nome"
                                    type="nome"
                                    name="nome"
                                    onChange={formik.handleChange}
                                    value={formik.values.nome}
                                    />
                                    {formik.errors.nome && (
                                        <div className="error">{formik.errors.nome}</div>
                                    )}
                                </div>


                                <div className="input-box w-100" style={{ transition:sidebar?"200ms":"100ms"}}>
                                    <label htmlFor="email">E-mail de acesso<span style={{color:"red"}}>*</span></label>
                                    <input 
                                    placeholder="Digite o e-mail que será utilizado para acessar o sistema" 
                                    id="email"
                                    name="email"
                                    type="email"
                                    //value={email}
                                    //onChange={e => setEmail(e.target.value)}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    />
                                    {formik.errors.email && (
                                        <div className="error">{formik.errors.email}</div>
                                    )}
                                </div>

                                

                                <div className="input-box w-100" >
                                    <label htmlFor="password">Senha<span style={{color:"red"}}>*</span></label>                            
                                    <input 
                                    placeholder="Crie uma senha" 
                                    id="password"
                                    name="password"
                                    type="password"
                                    //value={password}
                                    //onChange={e => setPassword(e.target.value)}
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    />
                                    {formik.errors.password && (
                                        <div className="error">{formik.errors.password}</div>
                                    )}
                                </div>


                                <div className="input-box w-100">
                                    <label htmlFor="confirmPassword">Confirme a Senha<span style={{color:"red"}}>*</span></label>
                                    <input 
                                    placeholder="Digite a senha novamente" 
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type="password"
                                    //value={confirmpassword}
                                    //onChange={e => setConfirmPassword(e.target.value)}
                                    onChange={formik.handleChange}
                                    value={formik.values.confirmPassword}
                                    />
                                    {formik.errors.confirmPassword && (
                                        <div className="error">{formik.errors.confirmPassword}</div>
                                    )}
                                </div>

                                                                                    
        

                                <div className="input-box w-100" style={{ transition:"200ms"}}>
                                    <label>Vincular Empresa<span style={{color:"red"}}>*</span></label>
                                    {/*<!-- Custom select structure --> */}
                                    <div className="selectdiv-teste" style={{width:"100%", maxWidth:"100%"}}>
                                    <label style={{width:"100%"}}>
                                    <AsyncSelect
                                        cacheOptions
                                        loadOptions={callApi}
                                        onChange={(data) => {
                                        setselectData(data);
                                        }}
                                        value={selectData}
                                        defaultOptions
                                        id="vinculo"
                                        type="vinculo"
                                        name="vinculo"   
                                        className="asyncSelectInput"                                     
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,                                                
                                                margin:"0.6rem 0",
                                                paddingLeft: "2.5rem",
                                                height: "45px",
                                                border:"none",
                                                outline: state.isFocused?'1px solid #4070f4;':'0',
                                                borderRadius: "10px",
                                                boxShadow: "1px 1px 6px #0000001c",
                                                fontSize: "0.8rem",                                               
                                            }),
                                            input:(style) => ({
                                                ...style,
                                                color:"black",
                                            }),
                                            singleValue: (baseStyles) => ({
                                                ...baseStyles,
                                                color:selectData.value?"black":"gray",
                                            }),
                                            valueContainer: (baseStyles) => ({
                                                ...baseStyles,
                                                fontWeight: "lighter",
                                            }),
                                            option: (styles) => {
                                                return {
                                                ...styles,
                                                fontWeight: "lighter", 
                                                color: "black",                                               
                                                };
                                            },                                           
                                        }}
                                    />                                        
                                        {formik.errors.vinculo && (
                                        <div className="error" style={{fontWeight: "lighter"}}>{formik.errors.vinculo}</div>
                                        )}
                                    </label>
                                    </div>
                                    {/*<!-- Custom select structure --> */}
                                </div>  




                            </div>

                            {/* <div className="continue-button">
                                <button type="submit"><a>Cadastrar</a> </button>
                            </div> */}
                        </form>
                    {/* </div> */}

                </div>
            </Teste>                       
   
        </PermissionGate>       
        </>
        

        );
    };
    
    export default User;