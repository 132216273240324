import styled from "styled-components";
import React from 'react';
import InputMask from 'react-input-mask';
import PermissionGate from "../../services/PermissionGate";
import { useFormik, Field } from "formik";
import * as Yup from "yup";
import * as AiIcons from 'react-icons/ai';
import { updateAdicionaisGroup } from "../../services/api";
import cep from 'cep-promise';
import { AuthContext } from "../../contexts/auth";
import { useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { BsPlusCircle } from "react-icons/bs";
import { FaTrash } from 'react-icons/fa';

const DivEditCategoriaContainer = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showEditCategoria }) => (showEditCategoria ? 'none' : 'flex')};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
`;

const DivEditCategoria = styled.div`
  z-index: 20;
  position: absolute;
  top: 15%;
  left: 15%;
  float: left;
  min-width: 70%;
  min-height: 50%;
  max-width: 60%;
  max-height: 80%;
  padding: 10px 10px;
  list-style: none;
  --background-color: white;
  background: rgb(247,247,247)!important;
  background-clip: padding-box;
  border-radius: 15px;
`;

const ModalEditGrupoAdd = ({
  setEditCategoria,
  showEditCategoria,
  setRefresh,
  categoriaToEdit
}) => {
  const { user } = useContext(AuthContext);
  const empresa = localStorage.getItem('empresa');
  const empresaParse = JSON.parse(empresa);
  const idEmpresa = empresaParse.id_empresa;

  const [minimo, setMinimo] = useState(categoriaToEdit?.min ?? 0);
  const [maximo, setMaximo] = useState(categoriaToEdit?.max ?? 0);
  const [mandatory, setMandatory] = useState(categoriaToEdit?.mandatory ?? false);
  const [calcular_maior_valor, setCalcularMaiorValor] = useState(categoriaToEdit?.calcular_maior_valor ?? false)
  const [calcular_media, setCalcularMedia] = useState(categoriaToEdit?.calcular_media ?? false)
  const [precificacao, setPrecificacao] = useState(categoriaToEdit?.calcular_maior_valor ? "maior" : categoriaToEdit?.calcular_media ? "media" : "soma");
  const [type, setType] = useState(categoriaToEdit?.type === "pizza" ? "pizza" : "simples")
  //console.log("categoriaToEdit>", categoriaToEdit)
  useEffect(() => {
    if (categoriaToEdit) {
      setMinimo(categoriaToEdit.min ?? 0);
      setMaximo(categoriaToEdit.max ?? 0);
      setMandatory(categoriaToEdit.mandatory ?? false);
      setCalcularMaiorValor(categoriaToEdit.calcular_maior_valor ?? false);
      setCalcularMedia(categoriaToEdit.calcular_media ?? false);
      setPrecificacao(categoriaToEdit?.calcular_maior_valor ? "maior" : categoriaToEdit?.calcular_media ? "media" : "soma");
      setType(categoriaToEdit?.type === "pizza" ? "pizza" : "simples");
      //console.log("PASSOU AQUI?")
    }
    //console.log(JSON.stringify(categoriaToEdit));
  }, [showEditCategoria, categoriaToEdit]);

  const handleMinimoChange = (event) => {
    const value = Math.min(Math.max(0, event.target.valueAsNumber), maximo);
    setMinimo(value);
  };

  const handleMaximoChange = (event) => {
    const value = Math.max(minimo, event.target.valueAsNumber);
    setMaximo(value);
  };

  const incrementMinimo = () => {
    setMinimo((prevMinimo) => (prevMinimo < maximo ? prevMinimo + 1 : prevMinimo));
  };

  const decrementMinimo = () => {
    setMinimo((prevMinimo) => (prevMinimo > 0 ? prevMinimo - 1 : prevMinimo));
  };

  const incrementMaximo = () => {
    setMaximo((prevMaximo) => prevMaximo + 1);
  };

  const decrementMaximo = () => {
    setMaximo((prevMaximo) => (prevMaximo > minimo ? prevMaximo - 1 : prevMaximo));
  };

  useEffect(() => {
    // Ajusta minimo quando mandatory muda
    if (mandatory && minimo === 0) {
      setMinimo(1);
    } else if (!mandatory && minimo !== 0) {
      setMinimo(0);
    }
  }, [mandatory]);

  useEffect(() => {
    // Ajusta mandatory quando minimo muda
    setMandatory(minimo > 0);
  }, [minimo]);

  const SignUpSchema = Yup.object().shape({
    title: Yup.string().required("Obrigatório"),
  });

  const formik = useFormik({
    validationSchema: SignUpSchema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      title: categoriaToEdit?.title || "",
    },
    onSubmit: (values) => {
      const title = values.title;
      handleSubmitAPI(title);
    },
  });

  const handleSubmitAPI = (title) => {
    const userID = user._id;
    const adicional_objId = categoriaToEdit?._id;
    //const id_adicional = categoriaToEdit?.id_grupo_adicional;
    //const type = categoriaToEdit "simples";
    const calcularMaiorValor = precificacao==="maior" ? true : false;
    const calcularMedia = precificacao==="media" ? true : false;
    const categoriaData = {
      userID,
      adicional_objId,
      //id_adicional,
      idEmpresa,
      title,
      minimo,
      maximo,
      mandatory,
      calcularMaiorValor,
      calcularMedia,
      precificacao,
      type
    };
    console.log("Submit categoriaData:", categoriaData);
    updateAdicionaisGroup(categoriaData).then(response => {
      if (response.status === 200) {
        toast(response.data.msg, { autoClose: 5000, type: "success" });
        setRefresh(prev => !prev);
        setEditCategoria(!showEditCategoria);
      } else {
        toast(response.data.msg, { autoClose: 5000, type: "error" });
      }
    }).catch(err => {
      toast(err.response.data.msg, { autoClose: 5000, type: "error" });
    });
  };

  const closeEditCategoria = () => setEditCategoria(!showEditCategoria);

  return (
    <DivEditCategoriaContainer showEditCategoria={showEditCategoria}>
      <DivEditCategoria>
        <div className="m-5" style={{ width: "96%", marginLeft: "2%!important" }}>
          <div style={{ marginBottom: "20px", marginTop: "-30px", fontSize: "25px" }}>
            <a>Editar Grupo de Adicionais</a>
          </div>
          <form onSubmit={formik.handleSubmit} style={{ marginTop: "-30px", maxWidth: "100%" }}>
            <div style={{ display: "flex", justifyContent: "right" }}>
              <div className="continue-button" style={{ width: "150px", display: "flex", justifyContent: "right" }}>
                <button style={{ marginTop: "12px", height: "49.6px", width: "150px" }} type="submit">
                  <a><AiIcons.AiOutlineSave style={{ marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} />Salvar</a>
                </button>
              </div>
              <div className="back-button">
                <button onClick={closeEditCategoria} type="button">
                  <AiIcons.AiOutlineCloseCircle style={{ color: "#ff4c4c", marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} /><a>Fechar</a>
                </button>
              </div>
            </div>
            <div className="input-group-edit modalCategoria" style={{ paddingBottom: "0px", marginBottom: "0px", gap:10 }}>
              <div className="input-box" style={{ width: "29%" }}>
                <label>Tipo do grupo<span style={{ color: "red" }}>*</span></label>
                {/*<!-- Custom select structure --> */}
                <div className="selectdiv">
                  <label style={{ width: "100%" }}>
                    <select
                      id="type"
                      type="text"
                      name="type"
                      onChange={(e) => setType(e.target.value)}
                      value={type}
                      //className={calcular_maior_valor === "" ? 'invalid-option' : 'beleza'}
                      style={{ width: "100%" }}
                    >
                      <option value={"simples"} style={{ color: "black" }}>
                        Adicional simples
                      </option>
                      <option value={"pizza"} style={{ color: "black" }}>
                        Sabor de pizza
                      </option>
                    </select>
                  </label>
                </div>
                {/*<!-- Custom select structure --> */}
              </div>
              <div className="input-box" style={{ width: "69%" }}>
                <label>Nome do grupo de adicionais<span style={{ color: "red" }}>*</span></label>
                <input
                  placeholder="Digite o nome do grupo"
                  id="title"
                  type="title"
                  name="title"
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
                {formik.errors.title && (
                  <div className="error">{formik.errors.title}</div>
                )}
              </div>
              <div className="row-adicionais-2" style={{ justifyContent: "space-between", width: '100%' }}>
                <div className="input-box" style={{ width: "33%" }}>
                  <label style={{ color: 'gray', paddingBottom: 8 }}>Quantidade de adicionais que o cliente pode escolher?</label>
                  <div className="quantity-control">
                    <div className="quantity-control-individual">
                      <label>Mínimo</label>
                      <div className="input-groupCadastroAdicionais">
                        <button type="button" className="btnDecrement_" onClick={decrementMinimo}>-</button>
                        <input type="number" value={minimo} onChange={handleMinimoChange} style={{ padding: 10 }} />
                        <button type="button" className="btnIncrement_" onClick={incrementMinimo}>+</button>
                      </div>
                    </div>
                    <div className="quantity-control-individual">
                      <label>Máximo</label>
                      <div className="input-groupCadastroAdicionais">
                        <button type="button" className="btnDecrement_" onClick={decrementMaximo}>-</button>
                        <input type="number" value={maximo} onChange={handleMaximoChange} style={{ padding: 10 }} />
                        <button type="button" className="btnIncrement_" onClick={incrementMaximo}>+</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="radio-button-groupAdicionais" style={{ width: "33%" }}>
                  <div className="radio-optionAdicionais">
                    <input
                      id="opcional"
                      type="radio"
                      name="mandatory"
                      value="opcional"
                      checked={!mandatory}
                      onChange={() => setMandatory(false)}
                    />
                    <div className="labelOptAdicionais">
                      <label htmlFor="opcional">Opcional</label>
                      <span>Seu cliente pode optar por não escolher adicionais</span>
                    </div>
                  </div>
                  <div className="radio-optionAdicionais">
                    <input
                      id="obrigatorio"
                      type="radio"
                      name="mandatory"
                      value="obrigatorio"
                      checked={mandatory}
                      onChange={() => setMandatory(true)}
                    />
                    <div className="labelOptAdicionais">
                      <label htmlFor="obrigatorio">Obrigatório</label>
                      <span>Seu cliente precisa escolher adicionais</span>
                    </div>
                  </div>
                </div>
                <div className="input-box" style={{ width: "33%" }}>
                  <label>Precificação<span style={{ color: "red" }}>*</span></label>
                  {/*<!-- Custom select structure --> */}
                  <div className="selectdiv">
                    <label style={{ width: "100%" }}>
                      <select
                        id="calcular_maior_valor"
                        type="text"
                        name="calcular_maior_valor"
                        onChange={(e) => setPrecificacao(e.target.value)}
                        value={precificacao}
                        //className={calcular_maior_valor === "" ? 'invalid-option' : 'beleza'}
                        style={{ width: "100%" }}
                      >
                        <option value={"soma"} style={{ color: "black" }}>
                          Somatório
                        </option>
                        <option value={"media"} style={{ color: "black" }}>
                          Média
                        </option>
                        <option value={"maior"} style={{ color: "black" }}>
                          Maior Valor
                        </option>



                      </select>
                    </label>
                  </div>
                  {/*<!-- Custom select structure --> */}
                </div>
              </div>
            </div>
          </form>
        </div>
      </DivEditCategoria>
    </DivEditCategoriaContainer>
  );
};

export default ModalEditGrupoAdd;
