import React, { useState, useEffect } from 'react';
import { getAdicionaisCardapio } from "../../services/api";
import { Modal, Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import { FaCheck } from 'react-icons/fa';
import { MdFastfood } from "react-icons/md";

const ModalAdicionais = ({ show, onClose, item, idEmpresa, nomeEmpresaForUrl, updateCartItem, quantidades, setQuantidades, adicionaisCompletos, setAdicionaisCompletos, handleFinalizarAdicionais }) => {
    useEffect(() => {
        const fetchAdicionais = async () => {
            try {
                const response = await getAdicionaisCardapio(idEmpresa, nomeEmpresaForUrl);
                const adicionaisFromDB = response.data.adicionais;
                if (item.has_adicional && item.adicionais && adicionaisFromDB) {
                    const adicionaisEncontrados = adicionaisFromDB.filter(adicionalDB =>
                        item.adicionais.some(adicionalItem => adicionalItem.value === adicionalDB._id)
                    );
                    setAdicionaisCompletos(adicionaisEncontrados.map(adicional => ({
                        ...adicional,
                        quantidade: 0
                    })));
                }
            } catch (error) {
                console.error('Erro ao buscar adicionais:', error);
            }
        };
        fetchAdicionais();
    }, [idEmpresa, nomeEmpresaForUrl, item, setAdicionaisCompletos]);

    const handleIncrement = (adicionalPai, idPai, id) => {
        console.log("adicionalPai:", adicionalPai);
        const calculaMediaOuMaiorValor = adicionalPai.calcular_maior_valor || adicionalPai.calcular_media;
        console.log("calculaMediaOuMaiorValor:", calculaMediaOuMaiorValor)
        setQuantidades(prev => {
            const maxTotal = adicionaisCompletos.find(adicional => adicional._id === idPai).max;
            // Calcula o total de adicionais já selecionados no grupo (incluindo o adicional atual)
            const totalAtualNoGrupo = Object.keys(prev).reduce((acc, adicionalId) => {
                // Se for o adicional atual, somamos ele também
                acc += prev[adicionalId] || 0;
                return acc;
            }, 0);

            // Se o total de adicionais no grupo já atingiu ou ultrapassou o limite, não permite adicionar mais
            if (totalAtualNoGrupo >= maxTotal) {
                toast("Valor máximo permitido!", { autoClose: 1000, type: "warning" });
                return prev; // Não permite adicionar mais adicionais
            }

            // Verifica a quantidade do adicional específico que estamos tentando incrementar
            const totalAtualDoAdicional = prev[id] || 0;

            // Se o adicional tiver 'calcular_maior_valor' ou 'calcular_media', limita a 1
            if (calculaMediaOuMaiorValor && totalAtualDoAdicional >= 1) {
                toast("Valor máximo permitido!", { autoClose: 1000, type: "warning" });
                return prev; // Se já houver 1, não permite adicionar mais
            }

            // Para adicionais normais, apenas limita o total no grupo
            if (!calculaMediaOuMaiorValor && totalAtualNoGrupo + 1 > maxTotal) {
                toast("Valor máximo permitido!", { autoClose: 1000, type: "warning" });
                return prev; // Se o total no grupo exceder o maxTotal, não permite adicionar mais
            }

            // Se não houver violação de limites, incrementa o adicional
            return { ...prev, [id]: (prev[id] || 0) + 1 };
        });
    };

    const handleDecrement = (id) => {
        setQuantidades(prev => {
            if (prev[id] > 0) {
                return { ...prev, [id]: prev[id] - 1 };
            }
            return prev;
        });
    };

    /*const checkMandatoryAdicionais = () => {
        return adicionaisCompletos.every(grupo => {
            if (!grupo.mandatory) return true;
            const totalSelected = grupo.adicionais.reduce((acc, adicional) => acc + (quantidades[adicional._id] || 0), 0);
            return totalSelected >= grupo.min;
        });
    };*/
    const checkMandatoryAdicionais = () => {
        return adicionaisCompletos.every(grupo => {
            // Se o grupo não for obrigatório, podemos pular ele
            if (!grupo.mandatory) return true;

            // Verificar se há ao menos um subAdicional disponível (out === false)
            const hasValidSubAdicional = grupo.adicionais.some(subAdicional => !subAdicional.out);

            // Se não houver subAdicional disponível, ignore este grupo
            if (!hasValidSubAdicional) return true;

            // Se houver subAdicionais válidos, então fazemos a verificação de quantidade mínima
            const totalSelected = grupo.adicionais.reduce((acc, adicional) => acc + (quantidades[adicional._id] || 0), 0);
            return totalSelected >= grupo.min;
        });
    };

    /*const handleFinalizar = () => {
        if (!checkMandatoryAdicionais()) {
            toast("Selecione todos os adicionais obrigatórios e suas quantidades mínimas.", { autoClose: 2000, type: "warning" });
            return;
        }

        const selectedAdicionais = adicionaisCompletos.map(adicional => ({
            grupoId: adicional._id,
            grupoTitle: adicional.title,
            adicionais: adicional.adicionais.map(subAdicional => ({
                adicionalId: subAdicional._id,
                title: subAdicional.title,
                quantity: quantidades[subAdicional._id] || 0,
                price: subAdicional.price
            })).filter(subAdicional => subAdicional.quantity > 0)
        })).filter(adicional => adicional.adicionais.length > 0);

        handleFinalizarAdicionais(item, selectedAdicionais);
    };*/
    const handleFinalizar = () => {
        if (!checkMandatoryAdicionais()) {
            toast("Selecione todos os adicionais obrigatórios e suas quantidades mínimas.", { autoClose: 2000, type: "warning" });
            return;
        }

        const selectedAdicionais = adicionaisCompletos.map(adicional => ({
            grupoId: adicional._id,
            grupoTitle: adicional.title,
            calcular_maior_valor: adicional.calcular_maior_valor ?? false, // Inclua o campo calcular_maior_valor, garantindo que seja false se não estiver definido.
            calcular_media: adicional.calcular_media ?? false,
            adicionais: adicional.adicionais.map(subAdicional => ({
                adicionalId: subAdicional._id,
                title: subAdicional.title,
                quantity: quantidades[subAdicional._id] || 0,
                price: subAdicional.price
            })).filter(subAdicional => subAdicional.quantity > 0)
        })).filter(adicional => adicional.adicionais.length > 0);

        handleFinalizarAdicionais(item, selectedAdicionais);
    };

    const isSelected = (adicionalPai) => {
        const totalItems = adicionalPai.adicionais.reduce((acc, adicional) => {
            return acc + (quantidades[adicional._id] || 0);
        }, 0);

        return totalItems >= (adicionalPai.min || 1);
    };

    useEffect(() => {
        isRequirementMet();
    }, [quantidades]);  // Dependendo de quantidades para reavaliar quando mudar
    //console.log("adicionaisCompletos>>", adicionaisCompletos)
    const isRequirementMet = () => {
        return adicionaisCompletos.every(adicionalPai => {
            if (!adicionalPai.mandatory) {
                // Ignora se não for obrigatório
                return true;
            }

            if (adicionalPai.adicionais.length === 0) {
                // Considera atendido se não há itens adicionais (não deveria ocorrer se for obrigatório e tiver min > 0)
                return true;
            }

            // Calcula a soma total de itens adicionados no grupo obrigatório
            const totalItems = adicionalPai.adicionais.reduce((acc, adicional) => {
                return acc + (quantidades[adicional._id] || 0);
            }, 0);

            // Verifica se o total atende ao mínimo requerido, se houver um mínimo definido
            return adicionalPai.min > 0 ? totalItems >= adicionalPai.min : true;
        });
    };


    return (
        <Modal show={show} onHide={onClose} dialogClassName="custom-modal-adicionais">

            <Modal.Header closeButton>
                <Modal.Title>{item.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {adicionaisCompletos?.map((adicional, index) => (
                    <div key={adicional._id}>
                        <div className="adicional-group">
                            {adicional.adicionais.some(subAdicional => !subAdicional.out) && (
                                <>
                                    <div className='line' style={{ marginTop: 10 }}></div>
                                    <div className='childrenDivTitleAdicionalCardapio'>
                                        <span style={{ fontWeight: '600' }}>{adicional.title}</span>
                                        {adicional.mandatory && (
                                            <>
                                                <label className={isSelected(adicional) ? "hidden" : "visible"}>Obrigatório</label>
                                                <FaCheck className={isSelected(adicional) ? "visible" : "hidden"} style={{ color: 'green', fontSize: '1.2em' }} />
                                            </>
                                        )}
                                    </div>
                                    <div style={{marginBottom:10}}>
                                        <span className='textMandatoryQtd'>Escolha {adicional.mandatory && adicional.min > 1 ? `no mínimo ${adicional.min},` : null} até {adicional.max === 1 ? adicional.max + " opção." : adicional.max + " opções."}</span>
                                    </div>
                                </>
                            )
                            }
                            <div className='adicionaisScrollModalAdd'>
                                {adicional.adicionais.map(subAdicional => {
                                    if (!subAdicional.out) {

                                        return (
                                            <div key={subAdicional._id} className={'itens selected'}>
                                                <div className="divItemImg">
                                                    {subAdicional.image && subAdicional.image !== "" ?
                                                        <img width={100} height={75} src={subAdicional.image} alt={subAdicional.title} />
                                                        :
                                                        <div className="iconWhenImgNull">
                                                            <MdFastfood style={{ fontSize: 75 }} />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="divItemName">
                                                    <span>{subAdicional.title}</span>
                                                </div>

                                                <div className="item-selected-amount">
                                                    <div className="item-price-selected">
                                                        R$ {(subAdicional.price
                                                        ).toFixed(2).toString().replace('.', ',')}
                                                    </div>
                                                    <div className="pedeja-input">
                                                        <div className="input-number">
                                                            <button className="decrement-item"
                                                                onClick={() => handleDecrement(subAdicional._id)}
                                                                type="button"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ color: '#2b2b2b' }}><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                                            </button>
                                                            <input type="number" value={quantidades[subAdicional._id] || 0} readOnly />
                                                            <button className="increment-item"
                                                                onClick={() => handleIncrement(adicional, adicional._id, subAdicional._id)}
                                                                type="button"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ color: '#2b2b2b' }}><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        );
                                    }
                                    return null; // Garante que nada será renderizado se subAdicional.out for true
                                })}

                            </div>
                        </div>
                        {/*index !== adicionaisCompletos.length - 1 && (
                            <div className='line' style={{ marginTop: 10 }}></div>
                        )*/}
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Fechar
                </Button>
                <Button variant="primary" onClick={handleFinalizar}>
                    Incluir Adicionais
                </Button>
            </Modal.Footer>

        </Modal >
    );
};

export default ModalAdicionais;

